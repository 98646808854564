'use client';

import { FC, useRef, useState } from 'react';
import cn from 'classnames';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import styles from './projects.module.scss';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../components/icons';
import { content } from './projects.content';
import { Button } from '../../../ui-kit';
import { PropjectsPopups } from './popups';

gsap.registerPlugin(ScrollTrigger);

const getSlideWithStatus = (currentSlide: number, length: number) => {
  const slides: Record<string, number> = {
    active: currentSlide,
  };

  if (currentSlide < length - 1) {
    slides.next = currentSlide + 1;
  } else {
    slides.next = 0;
  }

  if (currentSlide > 0) {
    slides.prev = currentSlide - 1;
  } else {
    slides.prev = length - 1;
  }

  if (currentSlide > 1) {
    slides.prevSecond = currentSlide - 2;
  } else if (currentSlide === 1) {
    slides.prevSecond = length - 1;
  } else if (currentSlide === 0) {
    slides.prevSecond = length - 2;
  }

  if (currentSlide > 2) {
    slides.prevHidden = currentSlide - 3;
  } else if (currentSlide === 2) {
    slides.prevHidden = length - 1;
  } else if (currentSlide === 1) {
    slides.prevHidden = length - 2;
  } else if (currentSlide === 0) {
    slides.prevHidden = length - 3;
  }

  return slides;
};

export const Projects: FC = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const activeSlides = getSlideWithStatus(activeSlide, content.length);

  const wrapRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  useGSAP(
    () => {
      const innerTL = gsap.timeline({
        repeat: 0,
        scrollTrigger: innerRef.current,
      });

      innerTL.to(innerRef.current, {
        opacity: '1',
        duration: 0.5,
        delay: 0.5,
      });
    },
    { scope: innerRef }
  );

  const modalBlockerRef = useRef(false);

  const handleCloseModal = () => {
    if (modalBlockerRef.current) {
      return;
    }
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    modalBlockerRef.current = true;
    setTimeout(() => {
      modalBlockerRef.current = false;
    }, 100);
    setModalOpen(true);
  };

  const handleNext = () => {
    const newSlideIdx = activeSlide < content.length - 1 ? activeSlide + 1 : 0;
    setActiveSlide(newSlideIdx);
  };

  const handlePrev = () => {
    const newSlideIdx = activeSlide > 0 ? activeSlide - 1 : content.length - 1;
    setActiveSlide(newSlideIdx);
  };

  return (
    <div className={styles.wrap} ref={wrapRef}>
      <PropjectsPopups
        currentSlide={activeSlide}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
      <div className={styles.inner} ref={innerRef} id='projects'>
        <h2 className={styles.title}>
          реализованные и&nbsp;перспективные проекты
        </h2>
        <div className={styles.slider}>
          <button
            type="button"
            className={cn(styles.navButton, styles.navButtonLeft)}
            onClick={handlePrev}
          >
            <ChevronLeftIcon aria-label="prev" />
          </button>
          <div className={styles.sliderContent}>
            <svg
              width="1075"
              height="866"
              viewBox="0 0 1075 866"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.line}
            >
              <path
                d="M1068.94 526.487C1027.88 759.359 756.646 906.278 463.023 854.504C169.4 802.73 -35.2238 571.906 5.83795 339.034C46.8997 106.161 318.13 -40.7575 611.753 11.0162C905.376 62.7899 1110 293.614 1068.94 526.487Z"
                stroke="url(#paint0_linear_636_3624)"
                strokeWidth="0.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_636_3624"
                  x1="378.98"
                  y1="831.41"
                  x2="443.816"
                  y2="649.806"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="white" />
                  <stop offset="1" stopColor="#999999" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>

            {content.map((item, idx) => {
              return (
                <div
                  key={item.id}
                  className={cn(styles.slide, styles[`slide_${idx}`], {
                    [styles.slideActive]: idx === activeSlides.active,
                    [styles.slideNext]: idx === activeSlides.next,
                    [styles.slidePrev]: idx === activeSlides.prev,
                    [styles.slidePrevSecond]: idx === activeSlides.prevSecond,
                    [styles.slidePrevHidden]: idx === activeSlides.prevHidden,
                  })}
                >
                  <picture>
                    <source srcSet={item.imageWebp} type="image/webp" />
                    <img
                      src={item.imagePng}
                      alt={item.info.title}
                      className={styles.slideImage}
                    />
                  </picture>
                </div>
              );
            })}
            <div className={styles.slideInfo}>
              {content.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className={cn(styles.slideInfoInner, {
                      [styles.slideInfoInnerActive]: idx === activeSlide,
                    })}
                  >
                    <p className={styles.infoDate}>{item.info.title}</p>
                    <p className={styles.infoText}>{item.info.text}</p>
                    <Button
                      className={styles.projectBtn}
                      onClick={handleOpenModal}
                    >
                      Посмотреть этот проект
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
          <button
            type="button"
            className={cn(styles.navButton, styles.navButtonRight)}
            onClick={handleNext}
          >
            <ChevronRightIcon aria-label="next" />
          </button>
        </div>
      </div>
    </div>
  );
};
