import { Contract } from 'ethers';

export const getNextLevelRequirements = async (
  currentLevel: number,
  saleContract: Contract
) => {
  // Вообще в контракте там надо передавать индекс уровня, но нам нужен следующий уровень, так lvl - 1 + 1 = lvl
  const data = await saleContract.getFunction('levelsArray')(currentLevel);
  const amount = Number((data[0] as bigint) / BigInt(10 ** 18));
  const teamAmount = Number((data[1] as bigint) / BigInt(10 ** 18));
  const bonusAmount = Number((data[2] as bigint) / BigInt(10 ** 18));
  return {
    amount,
    teamAmount,
    bonusAmount,
  };
};
