import { Header } from '../../components/profile';
import { Cabinet } from '../../components/profile/subpages/cabinet';
import { useForceAccount } from '../../hooks/useForceAccount';
import styles from './profile.module.scss';

export function ProfilePage() {
  useForceAccount();

  return (
    <>
      <Header />
      <main className={styles.main}>
        <Cabinet />
      </main>
    </>
  );
}
