'use client';

import { FC, useRef } from 'react';
import styles from './invest.module.scss';
import { ArrowIcon } from '../../../components/icons';
import { useGSAP } from '@gsap/react';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import gsap from 'gsap';

gsap.registerPlugin(ScrollTrigger);

export const Invest: FC = () => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const titleTopRef = useRef<HTMLSpanElement>(null);
  const titleBottomRef = useRef<HTMLSpanElement>(null);

  const contentItem1Ref = useRef<HTMLDivElement>(null);
  const contentItem2Ref = useRef<HTMLDivElement>(null);
  const contentItem3Ref = useRef<HTMLDivElement>(null);
  const contentItem4Ref = useRef<HTMLDivElement>(null);
  const contentItem5Ref = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    const titleTopTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const titleBottomTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const contentItem1TL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const contentItem2TL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const contentItem3TL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const contentItem4TL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const contentItem5TL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });

    titleTopTL.to(titleTopRef.current, {
      opacity: '1',
      duration: 0.5,
    });

    titleBottomTL.to(titleBottomRef.current, {
      opacity: '1',
      duration: 0.5,
      delay: 0.3,
    });

    contentItem1TL.to(contentItem1Ref.current, {
      transform: 'none',
      duration: 0.5,
      delay: 0.8,
    });

    contentItem2TL.to(contentItem2Ref.current, {
      transform: 'none',
      duration: 0.5,
      delay: 1.3,
    });

    contentItem3TL.to(contentItem3Ref.current, {
      transform: 'none',
      duration: 0.5,
      delay: 1.8,
    });

    contentItem4TL.to(contentItem4Ref.current, {
      transform: 'none',
      duration: 0.5,
      delay: 2.3,
    });

    contentItem5TL.to(contentItem5Ref.current, {
      transform: 'none',
      duration: 0.5,
      delay: 2.8,
    });
  });

  return (
    <div className={styles.wrap}>
      <div className={styles.inner} ref={wrapRef}>
        <h2 className={styles.title}>
          <span className={styles.titleTop} ref={titleTopRef}>
            Инвестируя в производство
          </span>
          <span className={styles.titleBottom} ref={titleBottomRef}>
            инноваций, вы получите:
          </span>
        </h2>
        <div className={styles.content}>
          <div className={styles.contentItem} ref={contentItem1Ref}>
            <ArrowIcon className={styles.arrowIcon} />
            <span className={styles.contentItemText}>
              Рост инвестиций более, чем&nbsp;
              <span className={styles.contentAccent}>на 60% в год</span>
            </span>
          </div>
          <div className={styles.contentItem} ref={contentItem2Ref}>
            <ArrowIcon className={styles.arrowIcon} />
            <span className={styles.contentItemText}>
              Доступ к уникальным продуктам{' '}
              <span className={styles.contentAccent}>
                на&nbsp;льготных условиях
              </span>
            </span>
          </div>
          <div className={styles.contentItem} ref={contentItem3Ref}>
            <ArrowIcon className={styles.arrowIcon} />
            <span className={styles.contentItemText}>
              Участие в закрытых
              <span className={styles.contentAccent}>жилищных программах</span>
            </span>
          </div>
          <div className={styles.contentItem} ref={contentItem4Ref}>
            <ArrowIcon className={styles.arrowIcon} />
            <span className={styles.contentItemText}>
              Карьерный рост с&nbsp;входом
              <span className={styles.contentAccent}>
                в&nbsp;совет директоров
              </span>
            </span>
          </div>
          <div className={styles.contentItem} ref={contentItem5Ref}>
            <ArrowIcon className={styles.arrowIcon} />
            <span className={styles.contentItemText}>
              Эффективную
              <span className={styles.contentAccent}>
                партнерскую программу
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
