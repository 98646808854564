'use client';

import { FC, useRef } from 'react';
import gsap, { Linear } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import styles from './about.module.scss';

gsap.registerPlugin(ScrollTrigger);

export const About: FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const containerRef = useRef<HTMLDivElement>(null);

  const wrapRef = useRef<HTMLDivElement>(null);
  const sparkRef = useRef<HTMLImageElement>(null);
  const lineRef = useRef<SVGSVGElement>(null);
  const firstText = useRef<HTMLParagraphElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  const gradientLineRef = useRef<SVGLinearGradientElement>(null);

  const tag1Ref = useRef<HTMLLIElement>(null);
  const tag2Ref = useRef<HTMLLIElement>(null);
  const tag3Ref = useRef<HTMLLIElement>(null);
  const tag4Ref = useRef<HTMLLIElement>(null);
  const tag5Ref = useRef<HTMLLIElement>(null);
  const tag6Ref = useRef<HTMLLIElement>(null);
  const tag7Ref = useRef<HTMLLIElement>(null);
  const tag8Ref = useRef<HTMLLIElement>(null);

  const lineRefMobile = useRef<SVGSVGElement>(null);
  const sparkRefMobile = useRef<HTMLImageElement>(null);

  useGSAP(
    () => {
      const firstTextTL = gsap.timeline({
        repeat: 0,
        scrollTrigger: containerRef.current,
      });
      const textTL = gsap.timeline({
        repeat: 0,
        scrollTrigger: containerRef.current,
      });

      const tag1TL = gsap.timeline({
        repeat: 0,
        scrollTrigger: containerRef.current,
      });
      const tag2TL = gsap.timeline({
        repeat: 0,
        scrollTrigger: containerRef.current,
      });
      const tag3TL = gsap.timeline({
        repeat: 0,
        scrollTrigger: containerRef.current,
      });
      const tag4TL = gsap.timeline({
        repeat: 0,
        scrollTrigger: containerRef.current,
      });
      const tag5TL = gsap.timeline({
        repeat: 0,
        scrollTrigger: containerRef.current,
      });
      const tag6TL = gsap.timeline({
        repeat: 0,
        scrollTrigger: containerRef.current,
      });
      const tag7TL = gsap.timeline({
        repeat: 0,
        scrollTrigger: containerRef.current,
      });
      const tag8TL = gsap.timeline({
        repeat: 0,
        scrollTrigger: containerRef.current,
      });

      if (!isMobile) {
        const sparkTL = gsap.timeline({
          repeat: 0,
          scrollTrigger: containerRef.current,
        });
        const lineTL = gsap.timeline({
          repeat: 0,
          scrollTrigger: containerRef.current,
        });
        const gradientLineTL = gsap.timeline({
          repeat: 0,
          scrollTrigger: containerRef.current,
        });

        gradientLineTL.to(gradientLineRef.current, {
          attr: {
            x1: '400',
            y1: '179',
            x2: '38',
            y2: '-76',
          },
          duration: 0.5,
          delay: 5.5,
        });
        sparkTL.to(sparkRef.current, {
          opacity: '1',
          duration: 0.5,
        });
        sparkTL.to(sparkRef.current, {
          bottom: '5.5%',
          duration: 0.3,
          ease: Linear.easeNone,
        });
        sparkTL.to(sparkRef.current, {
          bottom: '3.3%',
          left: '0.5%',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkTL.to(sparkRef.current, {
          bottom: '0',
          left: '1%',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkTL.to(sparkRef.current, {
          bottom: '-3.3%',
          left: '1.5%',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkTL.to(sparkRef.current, {
          bottom: '-5.5%',
          left: '2.3%',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkTL.to(sparkRef.current, {
          bottom: '-8.3%',
          left: '2.9%',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkTL.to(sparkRef.current, {
          bottom: '-11%',
          left: '3.2%',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkTL.to(sparkRef.current, {
          bottom: '-13.5%',
          left: '4%',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkTL.to(sparkRef.current, {
          bottom: '-18%',
          left: '4.5%',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkTL.to(sparkRef.current, {
          left: '21%',
          duration: 0.5,
          ease: Linear.easeNone,
        });
        sparkTL.to(sparkRef.current, {
          left: '53%',
          duration: 0.5,
          ease: Linear.easeNone,
          delay: 3.7,
        });

        lineTL.to(lineRef.current, {
          opacity: '1',
          duration: 0.5,
        });
      }

      if (isMobile) {
        const lineMobileTL = gsap.timeline({
          repeat: 0,
          scrollTrigger: containerRef.current,
        });
        const sparkMobileTL = gsap.timeline({
          repeat: 0,
          scrollTrigger: containerRef.current,
        });
        lineMobileTL.to(lineRefMobile.current, {
          opacity: '1',
          duration: 0.5,
        });
        sparkMobileTL.to(sparkRefMobile.current, {
          opacity: '1',
          duration: 0.5,
        });
        sparkMobileTL.to(sparkRefMobile.current, {
          bottom: '10px',
          duration: 0.3,
          ease: Linear.easeNone,
        });
        sparkMobileTL.to(sparkRefMobile.current, {
          bottom: '4px',
          left: '3px',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkMobileTL.to(sparkRefMobile.current, {
          bottom: '-1px',
          left: '5px',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkMobileTL.to(sparkRefMobile.current, {
          bottom: '-5px',
          left: '7px',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkMobileTL.to(sparkRefMobile.current, {
          bottom: '-10px',
          left: '10px',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkMobileTL.to(sparkRefMobile.current, {
          bottom: '-13px',
          left: '14px',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkMobileTL.to(sparkRefMobile.current, {
          bottom: '-16px',
          left: '20px',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkMobileTL.to(sparkRefMobile.current, {
          bottom: '-18px',
          left: '23px',
          duration: 0.02,
          ease: Linear.easeNone,
        });
        sparkMobileTL.to(sparkRefMobile.current, {
          left: '78px',
          duration: 0.2,
          ease: Linear.easeNone,
        });
      }

      firstTextTL.to(firstText.current, {
        opacity: '1',
        duration: 0.5,
        delay: 1.7,
      });
      firstTextTL.to(firstText.current, {
        opacity: '0',
        visibility: 'hidden',
        duration: 0.5,
        delay: 3,
      });

      textTL.to(textRef.current, {
        opacity: '1',
        duration: 0.5,
        delay: 6,
      });

      tag1TL.to(tag1Ref.current, {
        opacity: '1',
        duration: 0.5,
        delay: 6.5,
      });
      tag2TL.to(tag2Ref.current, {
        opacity: '1',
        duration: 0.5,
        delay: 7,
      });
      tag3TL.to(tag3Ref.current, {
        opacity: '1',
        duration: 0.5,
        delay: 7.5,
      });
      tag4TL.to(tag4Ref.current, {
        opacity: '1',
        duration: 0.5,
        delay: 8,
      });
      tag5TL.to(tag5Ref.current, {
        opacity: '1',
        duration: 0.5,
        delay: 8.5,
      });
      tag6TL.to(tag6Ref.current, {
        opacity: '1',
        duration: 0.5,
        delay: 9,
      });
      tag7TL.to(tag7Ref.current, {
        opacity: '1',
        duration: 0.5,
        delay: 9.5,
      });
      tag8TL.to(tag8Ref.current, {
        opacity: '1',
        duration: 0.5,
        delay: 10,
      });
    },
    { scope: wrapRef }
  );

  return (
    <div className={styles.wrap} ref={containerRef}>
      <div className={styles.inner} id="about">
        <div className={styles.lineBlock}>
          <div className={styles.lineInner}>
            <svg
              width="656"
              height="189"
              viewBox="0 0 656 189"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.line}
              ref={lineRef}
            >
              <path
                d="M1 0V138C1 165.614 23.3858 188 51 188H656"
                stroke="url(#paint0_linear_730_5730)"
                strokeOpacity="0.5"
                strokeWidth="2"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_730_5730"
                  x1="229"
                  y1="188"
                  x2="34"
                  y2="-45"
                  gradientUnits="userSpaceOnUse"
                  ref={gradientLineRef}
                >
                  {/* <animate attributeName="x1" from="229" to="400" begin="5.5s" dur="0.5s" fill="freeze"  /> 
            <animate attributeName="y1" from="188" to="179" begin="5.5s" dur="0.5s" fill="freeze" /> 
            <animate attributeName="x2" from="34" to="38" begin="5.5s" dur="0.5s" fill="freeze" /> 
            <animate attributeName="y2" from="-45" to="-76" begin="5.5s" dur="0.5s" fill="freeze" />  */}
                  <stop stopOpacity="0" />
                  <stop offset="0.385" stopColor="white" />
                  <stop offset="0.635" stopColor="white" />
                  <stop offset="1" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>

            <svg
              width="131"
              height="110"
              viewBox="0 0 131 110"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.lineMobile}
              ref={lineRefMobile}
            >
              <path
                d="M1 0V79C1 95.5685 14.4315 109 31 109H131"
                stroke="url(#paint0_linear_636_4068)"
                strokeOpacity="0.5"
                strokeWidth="1.44053"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_636_4068"
                  x1="80.2296"
                  y1="103.782"
                  x2="-21.3713"
                  y2="79.262"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopOpacity="0" />
                  <stop offset="0.385" stopColor="white" />
                  <stop offset="0.635" stopColor="white" />
                  <stop offset="1" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
            <img
              alt="spark"
              className={styles.spark}
              src="/images/main/about/spark.png"
              ref={sparkRef}
            />
            <img
              alt="spark"
              className={styles.sparkMobile}
              src="/images/main/about/spark.png"
              ref={sparkRefMobile}
            />
            <p className={styles.textFirst} ref={firstText}>
              LIME — технологический холдинг
            </p>
          </div>
        </div>
        <div className={styles.contentBlock}>
          <p className={styles.text} ref={textRef}>
            Мы объединяем отраслевые компании в одну экосистему, направленную
            на&nbsp;разработку, производство и продвижение инновационных решений
            в&nbsp;важных сферах:
          </p>
          <ul className={styles.list}>
            <li className={cn(styles.item, styles.item1)} ref={tag1Ref}>
              альтернативная энергетика
            </li>
            <li className={cn(styles.item, styles.item2)} ref={tag2Ref}>
              машиностроение
            </li>
            <li className={cn(styles.item, styles.item3)} ref={tag3Ref}>
              недвижимость
            </li>
            <li className={cn(styles.item, styles.item4)} ref={tag4Ref}>
              переработка отходов
            </li>
            <li className={cn(styles.item, styles.item5)} ref={tag5Ref}>
              информационные технологии
            </li>
            <li className={cn(styles.item, styles.item6)} ref={tag6Ref}>
              сырьевое производство
            </li>
            <li className={cn(styles.item, styles.item7)} ref={tag7Ref}>
              туризм
            </li>
            <li className={cn(styles.item, styles.item8)} ref={tag8Ref}>
              здоровье и красота
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
