import { Contract } from 'ethers';

export const getTeamAmount = async (
  address: string,
  firstLineReferrals: string[],
  saleContract: Contract
) => {
  const promises = firstLineReferrals.map(async (refAdress) => {
    const data = await saleContract.getFunction('userFirstLineAmount')(
      address,
      refAdress
    );

    return Number(data / BigInt(10 ** 18));
  });
  const amountArray = await Promise.all(promises);
  // transform to usdt, price is constant and equal to 0.2
  return amountArray.reduce((acc, val) => acc + val, 0);
};
