import { Contract } from 'ethers';

export const sellInvestToken = async (
  amount: number,
  saleContract: Contract
) => {
  const bigIntAmount = BigInt(amount) * BigInt(`1000000000000000000`);

  await saleContract.getFunction('returnTokens')(bigIntAmount);
};
