import { useEffect, useState } from 'react';
import { getPriceGraph } from '../../../../../../api';
import { PriceGraphPoint } from '../../../../../../api/types';

export const useGraphicData = () => {
  const [data, setData] = useState<PriceGraphPoint[]>([]);
  const [currentPrice, setCurrentPrice] = useState(0);

  useEffect(() => {
    const init = async () => {
      const newData = await getPriceGraph();
      const prettifiedData = newData.map((point) => ({
        ...point,
        price: parseFloat(point.price.toFixed(4)),
      }));
      setCurrentPrice(prettifiedData[0].price);
      setData(prettifiedData.reverse());
    };

    init().catch(console.error);
  }, []);

  return {
    data,
    currentPrice,
  };
};
