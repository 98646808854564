import { FC, useRef, useState } from 'react';
import { TopBar } from './topBar';
import { SecondBar } from './secondBar';
import { Table } from './table';
import { Modal } from './modal/modal';
import { ReceiveBonusModal } from './receiveBonusModal';

export const Partners: FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isReceiveBonusModalOpen, setReceiveBonusModalOpen] = useState(false);
  const disableModalStateChange = useRef(false);

  const showModal = () => {
    setModalOpen(true);
    disableModalStateChange.current = true;
    setTimeout(() => {
      disableModalStateChange.current = false;
    }, 100);
  };

  const closeModal = () => {
    if (disableModalStateChange.current) {
      return;
    }
    setModalOpen(false);
  };

  const showReceiveBonusModal = () => {
    setReceiveBonusModalOpen(true);
    disableModalStateChange.current = true;
    setTimeout(() => {
      disableModalStateChange.current = false;
    }, 100);
  };

  const closeReceiveBonusModal = () => {
    if (disableModalStateChange.current) {
      return;
    }
    setReceiveBonusModalOpen(false);
  };

  return (
    <div>
      <TopBar />
      <SecondBar
        showModal={showModal}
        showReceiveBonusModal={showReceiveBonusModal}
      />
      <Table />
      <Modal isOpen={isModalOpen} onClose={closeModal} />
      <ReceiveBonusModal
        isOpen={isReceiveBonusModalOpen}
        onClose={closeReceiveBonusModal}
      />
    </div>
  );
};
