'use client';

import { FC, useEffect, useRef } from 'react';
import styles from './popup.module.scss';
import cn from 'classnames';
import { CloseIcon } from '../../components/icons';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element | null;
  contentClassName?: string;
}

export const Popup: FC<Props> = ({
  isOpen,
  onClose,
  children,
  contentClassName,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(contentRef, onClose);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('lock-body');
    } else {
      document.body.classList.remove('lock-body');
    }
  }, [isOpen]);

  return (
    <div className={cn(styles.wrap, { [styles.wrapVisible]: isOpen })}>
      <div className={styles.inner}>
        <div className={cn(styles.content, contentClassName)} ref={contentRef}>
          <button
            className={styles.closeBtn}
            type="button"
            onClick={onClose}
            aria-label="Закрыть"
          >
            <CloseIcon className={styles.closeIcon} aria-label="Закрыть" />
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};
