import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={35}
    viewBox="0 0 36 35"
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="m2.58 4.053 13.832 13.448L2.58 30.948c-1.23 1.025.532 2.74 1.587 1.543l13.831-13.448L31.83 32.491c1.054 1.196 2.817-.518 1.587-1.543L19.585 17.502 33.417 4.053c.727-.695.204-1.896-.816-1.873a1.137 1.137 0 0 0-.77.331L17.997 15.959 4.167 2.51c-.211-.213-.501-.334-.863-.333-1.008.099-1.41 1.23-.724 1.875Z"
    />
  </svg>
);
export { SvgComponent as CloseIcon };
