import { Contract } from 'ethers';

export const getRateIn = async (
  priceRateContract: Contract,
  usdtAmount: number
) => {
  const tokenAmount = await priceRateContract.getFunction('getRateIn')(
    BigInt(10 ** 18) * BigInt(usdtAmount)
  );

  const data = Number(tokenAmount / BigInt(`1000000000000000000`)); // 10 ** 18
  // remove floating point errors
  return data;
};
