export const BONUSES_CONTRACT_ADDRESS =
  process.env.REACT_APP_BONUSES_CONTRACT_ADDRESS ?? '';

export const INVEST_TOKEN_CONTRACT_ADDRESS =
  process.env.REACT_APP_INVEST_TOKEN_CONTRACT_ADDRESS ?? '';

export const SALE_CONTRACT_ADDRESS =
  process.env.REACT_APP_SALE_CONTRACT_ADDRESS ?? '';

export const REWARD_TOKEN_CONTRACT_ADDRESS =
  process.env.REACT_APP_REWARD_TOKEN_CONTRACT_ADDRESS ?? '';

export const PRICE_RATE_CONTRACT_ADDRESS =
  process.env.REACT_APP_PRICE_RATE_CONTRACT_ADDRESS ?? '';

export const TEST_USDT_CONTRACT_ADDRESS =
  process.env.REACT_APP_TEST_USDT_CONTRACT_ADDRESS ?? '';
