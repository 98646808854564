import { Contract } from 'ethers';

export const increaseAllowance = async (
  tokenContract: Contract,
  contractAddress: string,
  delta: bigint | number
) => {
  const data = (await tokenContract.getFunction('increaseAllowance')(
    contractAddress,
    delta
  )) as { wait: (a: number) => Promise<unknown> };
  await new Promise((resolve) => setTimeout(resolve, 10_000));
};
