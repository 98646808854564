import {
  About,
  Assets,
  Deposit,
  Faq,
  Header,
  Hero,
  Invest,
  Presale,
  Projects,
} from '../../components/main';
import { FC, useRef, useState } from 'react';
import styles from './main.module.scss';

export const MainPage: FC = () => {
  const [isProviderSelectOpen, setProviderSelectOpen] = useState(false);
  const freeseProviderCloseRef = useRef(false);

  const handleOpenProvider = () => {
    freeseProviderCloseRef.current = true;
    setTimeout(() => {
      freeseProviderCloseRef.current = false;
    }, 100);
    setProviderSelectOpen(true);
  };
  const handleCloseProvider = () => {
    if (freeseProviderCloseRef.current) {
      return;
    }
    setProviderSelectOpen(false);
  };

  return (
    <>
      <Header
        handleCloseProvider={handleCloseProvider}
        handleOpenProvider={handleOpenProvider}
        isProviderSelectOpen={isProviderSelectOpen}
      />
      <main className={styles.main}>
        <Hero />
        <About />
        <Projects />
        <Assets />
        <Invest />
        <Deposit />
        <Presale openProviderSelect={handleOpenProvider} />
        <Faq />
      </main>
    </>
  );
};
