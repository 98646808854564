import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={22}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={2}
      d="m1 21 8.586-8.586a2 2 0 0 0 0-2.828L1 1"
    />
  </svg>
);
export { SvgComponent as ChevronRightIcon };
