'use client';

import { FC, useRef } from 'react';
import cn from 'classnames';
import styles from './providerSelect.module.scss';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { useConnect } from '../../../hooks/web3/useConnect';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export const ProviderSelect: FC<Props> = ({ isOpen, handleClose }) => {
  const { handleConnect } = useConnect();

  const wrapRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(wrapRef, handleClose);

  const handleSelectProvider = (providerName: 'Metamask' | 'WalletConnect') => async () => {
    await handleConnect(providerName);
    handleClose();
  }

  return (
    <div className={cn(styles.overlay, { [styles.overlayActive]: isOpen })}>
      <div className={styles.wrap} ref={wrapRef}>
        <nav className={styles.nav}>
          <button
            className={styles.navLink}
            onClick={handleSelectProvider('Metamask')}
          >
            Metamask
          </button>
          <button
            className={styles.navLink}
            onClick={handleSelectProvider('WalletConnect')}
          >
            WalletConnect
          </button>
        </nav>
      </div>
    </div>
  );
};
