import { Contract } from 'ethers';

export const getSpendUsdtAmount = async (
  saleContract: Contract,
  account: string
) => {
  const contractAddress = await saleContract.getAddress();
  console.log('getSpendUsdtAmount', account);
  console.log('getSpendUsdtAmount', contractAddress);

  try {
    const data =
      ((await saleContract.getFunction('_totalSpendUsdt')(account)) as bigint) /
      BigInt('1000000000000000000');
    return Number(data);
  } catch (e) {
    console.error('getSpendUsdtAmount', e);
    return 0;
  }
};
