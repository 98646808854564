import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={39}
    height={46}
    fill="none"
    {...props}
  >
    <g fill="#fff" clipPath="url(#aPdfIcon)">
      <path d="M6.044 29.619c-.27-.145-.611-.217-1.007-.217-.395 0-.707.033-.865.076v2.856c.2.056.454.077.76.077.445 0 .822-.09 1.112-.265.43-.257.67-.7.67-1.304 0-.605-.247-.997-.67-1.223ZM10.865 29.402c-.475 0-.834.045-1.076.099v5.8c.232.042.57.054.928.054 1.963 0 3.029-1.134 3.029-3.117.01-1.734-.94-2.836-2.881-2.836Z" />
      <path d="M20.604 26.377H2.384C1.069 26.377 0 27.48 0 28.841v7.088c0 1.36 1.068 2.464 2.385 2.464h18.219c1.317 0 2.384-1.103 2.384-2.464v-7.088c0-1.361-1.067-2.464-2.384-2.464ZM7.085 32.368a2.36 2.36 0 0 1-1.04.64c-.343.111-.727.168-1.133.168-.274 0-.528-.012-.738-.066v2.944h-.918v-7.26c.242-.042.525-.08.852-.107.272-.021.574-.035.91-.035.387 0 .73.04 1.028.116.402.102.724.27.966.495.39.348.623.883.623 1.536s-.19 1.189-.548 1.57h-.002Zm6.598 2.693c-.665.687-1.762 1.057-3.144 1.057-.655 0-1.203-.032-1.667-.087v-7.227c.559-.088 1.223-.153 1.952-.153 1.319 0 2.258.316 2.88.916.632.6 1.002 1.45 1.002 2.638s-.359 2.18-1.024 2.855Zm6.05-5.56H16.82v2.442h2.69v.784h-2.69v3.325h-.918v-7.348h3.83v.797Z" />
      <path d="m38.297 12.423-11.96-10.42L24.414.266A1.022 1.022 0 0 0 23.73 0H6.094C4.998 0 4.11.918 4.11 2.05v21.5c0 .24.189.435.422.435h1.095a.428.428 0 0 0 .422-.435V2.003H23.33v10c0 1.133.888 2.051 1.985 2.051h11.748v29.943H6.048V41.22a.428.428 0 0 0-.422-.436H4.531a.428.428 0 0 0-.422.436v2.729c0 1.132.89 2.05 1.985 2.05h30.923c1.096 0 1.985-.918 1.985-2.05V13.99c0-.604-.259-1.178-.706-1.568Z" />
    </g>
    <defs>
      <clipPath id="aPdfIcon">
        <path fill="#fff" d="M0 0h39v46H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { SvgComponent as PdfIcon };
