import { FC } from 'react';
import cn from 'classnames';
import styles from './projects.module.scss';
import { PdfIcon } from '../../icons';
import { Popup } from '../../../ui-kit';

export const PopupBugsContent: FC = () => {
  return (
    <div className={cn(styles.popupWrap, styles.popupWrapBug)}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>Баги и мотоциклы</span>
        <span className={styles.popupTitleDate}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />I кв
          2022 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={styles.popupText}>
            Производство и модернизация малой мототехники (баги, квадроциклы,
            мотоциклы)
          </p>
          <p className={cn(styles.popupText, styles.popupTextBold)}>
            Для ценителей активного отдыха компания «Lime» производит
            высококлассную технику:
            <br />
            Багги , квадроциклы , трайки , велосипеды, самокаты.
          </p>
          <p className={styles.popupText}>
            Техника компании отличается гарантированной надежностью и повышенной
            проходимостью, что позволит использовать ее как в целях активного
            отдыха, так и для выполнения специфических задач.
          </p>
        </div>
        <img
          className={styles.popupImageBugs}
          src="/images/main/projects/motorcycle.webp"
        />
      </div>
      <div className={styles.popupMiddleCard}>
        Заботясь об окружающей среде и состоянии экосистемы, команда
        разработчиков переводит всю технику{' '}
        <span className={styles.textAccent}>
          на&nbsp;электро&nbsp;обеспечение.{' '}
        </span>
      </div>
      <ul className={styles.popupList}>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Благодаря разработке генератора собственного производства
          увеличивается ход транспорта в 3 раза от имеющихся на рынке аналогов,
          т&nbsp;е. владелец до&nbsp;следующей подзарядки проедет путь в три
          раза больше.
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Время полной зарядки займет всего 30 мин.
        </li>
      </ul>
      <a className={styles.popupLargeLink} href="#" target="_blank">
        <PdfIcon className={styles.pdfIcon} />
        Баги и мотоциклы
      </a>
    </div>
  );
};

export const PopupShampooContent: FC = () => {
  return (
    <div className={styles.popupWrap}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>
          Производство премиальной продукции Din Premium
        </span>
        <span className={styles.popupTitleDate}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          III кв 2022 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={cn(styles.popupText, styles.popupTextBold)}>
            DIN PREMIUM GROUP - будущее в области натуральных добавок. Компания,
            главной целью которой является забота о здоровье человека.
          </p>
          <p className={styles.popupText}>
            DIN PREMIUM совместно с Министерством сельского хозяйства Российской
            Федерации и АНО “Институт экологического здоровья” заключили
            соглашение об объединении своих научных, производственных и
            технических ресурсов для совместной разработки и производства
            высокоэффективной натуральной продукции, оказывающей комплексное
            оздоравливающее воздействие на организм человека.
          </p>
          <p className={styles.popupText}>
            Продукция с природным составом является уникальным источником
            витаминов и минералов в их идеальном соотношении для человека и
            обладает способностью подстраиваться под индивидуальные особенности
            каждого организма. Компания занимается производством и поставкой 100
            % органической продукции.
          </p>
        </div>
        <img
          className={styles.popupImageShampoo}
          src="/images/main/projects/shampoo.png"
        />
      </div>
      <h3 className={styles.popupListTitle}>DIN PREMIUM является:</h3>
      <ul className={styles.popupList}>
        <li className={cn(styles.popupItem, styles.popupItemShampoo)}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Крупнейшим производителем сыродавленных масел в России и капсул на их
          основе
        </li>
        <li className={cn(styles.popupItem, styles.popupItemShampoo)}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Единственным официальным поставщиком Йеменского дикого мёда SIDR в
          России
        </li>
        <li className={cn(styles.popupItem, styles.popupItemShampoo)}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Производителем сухих смесей и полностью органического протеина для
          спортсменов
        </li>
      </ul>
      <a
        className={styles.popupLargeLink}
        href="https://dinpremium.com/"
        target="_blank"
      >
        https://dinpremium.com/
      </a>
    </div>
  );
};

export const PopupPhoneContent: FC = () => {
  return (
    <div className={styles.popupWrap}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>Маркетплейс Dada Bazar</span>
        <span className={styles.popupTitleDate}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          III кв 2024 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={cn(styles.popupText, styles.popupTextBold)}>
            Мессенджер с широким функционалом
          </p>
          <p className={styles.popupText}>
            <span className={styles.popupTextBold}>Dadabazar</span> — это
            оптовый маркетплейс с товарами из Турции и Китая для розничной и
            оптовой торговли, где вы можете заказывать и продавать товары в
            несколько кликов.
          </p>
          <p className={styles.popupText}>
            Продавцы могут выставлять свои товары на площадке Dadabazar или
            заказывать оптом у надежных поставщиков для дальнейшей перепродажи в
            маркетплейсах, интернет-магазинах и розничных офлайн-магазинах.
          </p>
          <p className={styles.popupText}>
            Предприниматели могут легко найти поставщиков для оснащения своего
            бизнеса по выгодным ценам.
          </p>
          <p className={styles.popupText}>
            Также на площадке есть возможность заказывать товары в розницу, на
            выбор представлены десятки тысяч товаров самых разных категорий,
            включая одежду, товары для дома и электронику.
          </p>
          <p className={styles.popupText}>
            Мы работаем в товарном бизнесе с 2008 года. За это время мы
            совершили более 10.000 грузоперевозок. Мы основали площадку, на
            которой продавцы могут приобретать товары для своего бизнеса
            напрямую от производителей Турции и Китая, и продавать их своим
            клиентам.
          </p>
        </div>
        <img
          className={styles.popupImagePhone}
          src="/images/main/projects/phone.webp"
        />
      </div>
      <a
        className={styles.popupLargeLink}
        href="https://dadabazar.online/"
        target="_blank"
      >
        https://dadabazar.online/
      </a>
    </div>
  );
};

export const PopupStabilizeContent: FC = () => {
  return (
    <div className={cn(styles.popupWrap, styles.popupWrapStabilize)}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>
          Производство инверторных стабилизаторов
        </span>
        <span className={styles.popupTitleDate}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          IV кв 2024 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={styles.popupText}>
            Компания "Lime" представляет инверторный стабилизатор нового
            поколения, который обеспечивает стабильное напряжение в диапазоне от
            20 до 400 Вольт и полностью ликвидирует реактивные составляющие в
            сети.
          </p>
        </div>
        <img
          className={styles.popupImageStabilize}
          src="/images/main/projects/stabilize.webp"
        />
      </div>
      <p className={styles.popupText}>
        В отличие от других производителей, в этот стабилизатор встроены функции
        выбора: источник бесперебойного питания (UPS), подключения солнечных
        панелей и альтернативных источников энергии. Он может работать как в
        однофазных, так и в трехфазных сетях, с автоматической синхронизацией и
        нулевым проводом. Все это достигается без использования громоздких
        трансформаторов, что делает прибор компактным и бесшумным. С выходной
        мощностью от 1 до 60 кВт, стабилизатор от "Lime" является лучшим
        решением для обеспечения стабильного электроснабжения в любых условиях.
      </p>
      <h3 className={styles.popupListTitle}>Преимущества:</h3>
      <ul className={styles.popupList}>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Стабилизатор гарантированно держит от 20 вольт входящего напряжения;
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Заложены дополнительные функции: UPS, возможность подключения
          альтернативных источников энергии;
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Экономия электроэнергии на 20-40% благодаря фильтрации напряжения и
          полному исключению реактивных составляющих в сети;
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Себестоимость производства ниже, чем у конкурентов.
        </li>
      </ul>
      <p className={styles.popupText}>
        Область применения: крупные предприятия, использующие много
        электроэнергии; возобновляемые источники энергии; организации,
        использующие оборудование, требующее стабильного напряжения; операторы
        сотовой связи; медицинские, военные и другие учреждения; жилые дома с
        нестабильным низким напряжением.
      </p>
      <a className={styles.popupLargeLink} href="#" target="_blank">
        <PdfIcon className={styles.pdfIcon} />
        Инверторный стабилизатор
      </a>
    </div>
  );
};

export const PowerbankPopup: FC = () => {
  return (
    <div className={styles.popupWrap}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>
          Производство автономных пауербанков
        </span>
        <span className={styles.popupTitleDate}>
          <img
            src="/images/main/spark_white.png"
            className={styles.popupSpark}
          />
          II кв 2025 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={cn(styles.popupText, styles.popupTextBold)}>
            Всегда быть на связи – это основной девиз современного делового
            человека.
          </p>
          <p className={styles.popupText}>
            Электронномеханический powerbank - будущее, которое наступило уже
            сегодня. Этот маленький помощник зарядится сам и зарядит ваше
            устройство вне зависимости от того где вы находитесь и имеете ли
            доступ к электричеству.
          </p>
          <p
            className={cn(
              styles.popupText,
              styles.popupTextBold,
              styles.popupTextPoint
            )}
          >
            <img src="/images/main/spark.png" className={styles.popupSpark} />
            Он способен работать и заряжаться автономно.
          </p>
        </div>
        <img
          className={styles.popupImagePowerbank}
          src="/images/main/projects/powerbank.webp"
        />
      </div>
      <p className={styles.popupText}>
        Прелесть этого маленького чуда в том что, он должен быть снабжен всеми
        современными электронными начинками, такие как встроенный мощный фонарь,
        встроенный радиоприёмник, мп3, независимое автономное зарядное
        устройство для его работы и заряда АКБ. плюс его эргономика и дизайн
        должны быть компактным и футуристичным, самое главное привлекательным и
        удобным и необходимым продуктом для потребителя.
      </p>
      <a className={styles.popupLargeLink} href="#" target="_blank">
        <PdfIcon className={styles.pdfIcon} />
        Аннотация зарядного устройства
      </a>
    </div>
  );
};

export const PopupParaffinContent: FC = () => {
  return (
    <div className={styles.popupWrap}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>
          Производство медицинского парафина
        </span>
        <span className={styles.popupTitleDate}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          III кв 2024 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={styles.popupText}>
            Производство парафина из нефти по уникальной технологии
          </p>
          <p className={styles.popupText}>
            Командой Lime разработан уникальный технологический процесс
            по извлечению чистого парафина из нефти. Сырьём является парафиновая
            нефть.
          </p>
          <p className={styles.popupText}>
            Огромные запасы высокопарафинистых нефтей располагаются в России (в
            том числе в Прикаспийском нефтегазаносном бассейне),
            а также в Казахстане и на Украине.
          </p>
          <p className={cn(styles.popupText, styles.popupTextBold)}>
            С одной тонны сырья мы получаем 40% чистого парафина.
          </p>
        </div>
        <img
          className={styles.popupImageParaffin}
          src="/images/main/projects/paraffin.png"
        />
      </div>
      <h3 className={styles.popupListTitle}>
        Проект по производству парафина:
      </h3>
      <ul className={styles.popupList}>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Даст мощный импульс развитию нефтехимической отрасли в Республике
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Повысит престиж региона и страны на международном пространстве
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Создаст базу для подготовки современных высококвалифицированных
          технических специалистов;
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Приведёт к созданию ряда важнейших инновационных продуктов (за счёт
          функционирования лаборатории предприятия) в сфере нефтехимии и
          переработки отходов нефтегазовой отрасли;
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          Повысит поступление денежных средств в государственный бюджет
          территории за счёт налоговых отчислений предприятия и
          мультипликативного эффекта от инвестиций и выплаты заработной платы
          сотрудникам выше среднего уровня по стране.
        </li>
      </ul>
      <a className={styles.popupLargeLink} href="#" target="_blank">
        <PdfIcon className={styles.pdfIcon} />
        Производство парафина
      </a>
    </div>
  );
};

export const PoputHouseContent: FC = () => {
  return (
    <div className={cn(styles.popupWrap, styles.popupWrapHouses)}>
      <h2 className={styles.popupTitle}>
        <span className={styles.popupTitleText}>
          Производство автономных домов&nbsp;фахверк
        </span>
        <span className={styles.popupTitleDate}>
          <img
            src="/images/main/spark_white.png"
            className={styles.popupSpark}
          />
          IV кв 2025 г.
        </span>
      </h2>
      <div className={styles.popupBlockWithImage}>
        <div className={styles.blockWithText}>
          <p className={cn(styles.popupText, styles.popupTextBold)}>
            Благодаря нашим уникальным разработкам стали возможны автономные
            дома без центральных сетей, электромобили и катера без подзарядки и
            ограничений в движении!
          </p>
          <p className={styles.popupText}>
            Все это составляющие нового экологичного формата жизни без
            потребления и выбросов, который будет вписан в красивейший природный
            ландшафт на берегу большого водоема.
          </p>
          <p className={styles.popupText}>
            Речь идет об автономном эко-поселке на 1000 домов на площади 300Га с
            полной социальной инфраструктурой, системами обеспечения и
            безопасности.
          </p>
        </div>
        <img
          src="/images/main/projects/houses.webp"
          className={styles.popupHouseImage}
        />
      </div>
      <h3 className={styles.popupListTitle}>
        В инфраструктуру эко-поселка будут входить:
      </h3>
      <ul className={styles.popupList}>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          детсад и школа
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          многофункциональная больница
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          многофункциональный спортивный комплекс
          <br />
          (открытые и закрытые бассейны, тренажерные залы, теннисные корты,
          футбольное поле, баскетбольная и волейбольная площадки, картинг и др.)
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          торгово-развлекательный центр
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          магазины и рестораны 
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          фермерское хозяйство с теплицами 
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          мусороперерабатывающий комплекс
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          служба безопасности
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
           яхт-клуб
        </li>
        <li className={styles.popupItem}>
          <img src="/images/main/spark.png" className={styles.popupSpark} />
          вертолетная площадка
        </li>
      </ul>
      <a className={styles.popupLargeLink} href="#" target="_blank">
        <PdfIcon className={styles.pdfIcon} />
        Дома фахверк
      </a>
    </div>
  );
};

interface Props {
  currentSlide: number;
  isOpen: boolean;
  onClose: () => void;
}

export const PropjectsPopups: FC<Props> = ({
  currentSlide,
  isOpen,
  onClose,
}) => {
  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <>
        {(currentSlide === 0 && <PopupBugsContent />) || null}
        {(currentSlide === 1 && <PopupShampooContent />) || null}
        {(currentSlide === 2 && <PopupPhoneContent />) || null}
        {(currentSlide === 3 && <PopupParaffinContent />) || null}
        {(currentSlide === 4 && <PopupStabilizeContent />) || null}
        {(currentSlide === 5 && <PowerbankPopup />) || null}
        {(currentSlide === 6 && <PoputHouseContent />) || null}
      </>
    </Popup>
  );
};
