import { FC, useEffect, useState } from 'react';
import styles from './topBar.module.scss';
import {
  useBonusesContract,
  useInvestTokenContract,
  usePriceRateContract,
  useSaleContract,
} from '../../../../../hooks/web3/useContract';
import { useWeb3React } from '@web3-react/core';
import { getPartnersByAccount } from '../../../../../utils/web3/getPartnersByAccount';
import { getUserLevel } from '../../../../../utils/web3/getUserLevel';
import { getUserInvestTokenBalance } from '../../../../../utils/web3/getUserInvestTokenBalance';
import { getUsdtBalance } from '../../../../../utils/web3/getUsdtBalance';
import { getTeamAmount } from '../../../../../utils/web3/getTeamAmount';
import { getUserInfo } from '../../../../../api';
import {getSpendUsdtAmount} from "../../../../../utils/web3/getSpendUsdtAmount";

export const TopBar: FC = () => {
  const bonusesContract = useBonusesContract();
  const saleContract = useSaleContract();
  const investTokenContract = useInvestTokenContract();
  const priceRateContract = usePriceRateContract();

  const { account } = useWeb3React();
  const [bonus, setBonus] = useState(0);
  const [totalBonus, setTotalBonus] = useState(0);
  const [personalPartners, setPersonalPartners] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [teamBalance, setTeamBalance] = useState(0);
  const [level, setLevel] = useState(0);
  const [totalPartners, setTotalPartners] = useState(0);
  const [activePartners, setActivePartners] = useState(0);

  useEffect(() => {
    const init = async () => {
      if (
        !account ||
        !bonusesContract ||
        !saleContract ||
        !investTokenContract ||
        !priceRateContract
      ) {
        return;
      }

      const { total_users, structure_users } = await getUserInfo(account);
      setTotalPartners(total_users);
      setActivePartners(structure_users);

      const data = (await bonusesContract.getFunction('getBalance')(
        account
      )) as bigint;
      setBonus(Number(data / BigInt(10 ** 18)));

      const totalBonus = (await bonusesContract.getFunction('totalUserBonuses')(
        account
      )) as bigint;
      setTotalBonus(Number(totalBonus / BigInt(10 ** 18)));

      const partners = await getPartnersByAccount(saleContract, account);
      setPersonalPartners(partners.length);

      const newBalanceOf = await getUserInvestTokenBalance(
        investTokenContract,
        account
      );
      const usdtBalance = await getSpendUsdtAmount(saleContract, account);
      setUsdtBalance(usdtBalance);

      const newTeamBalance = await getTeamAmount(
        account,
        partners,
        saleContract
      );
      setTeamBalance(newTeamBalance);

      const newLevel = await getUserLevel(saleContract, account);
      setLevel(newLevel);
    };

    init().catch(console.error);
  }, [
    bonusesContract,
    account,
    saleContract,
    investTokenContract,
    priceRateContract,
  ]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.subWrapper} style={{ flexGrow: 3 }}>
        <div className={styles.item}>
          <span className={styles.itemText}>{totalPartners}</span>
          <span className={styles.divider} />
          <span className={styles.itemText}>Всего партнеров</span>
        </div>
        <div className={styles.item}>
          <span className={styles.itemText}>{activePartners}</span>
          <span className={styles.divider} />
          <span className={styles.itemText}>Активных партнеров</span>
        </div>
        <div className={styles.item}>
          <span className={styles.itemText}>{personalPartners}</span>
          <span className={styles.divider} />
          <span className={styles.itemText}>Личных партнеров</span>
        </div>
      </div>
      <div className={styles.subWrapper} style={{ flexGrow: 3 }}>
        <div className={styles.item}>
          <span className={styles.itemText}>{usdtBalance} USDT</span>
          <span className={styles.divider} />
          <span className={styles.itemText}>Личный портфель</span>
        </div>
        <div className={styles.item}>
          <span className={styles.itemText}>
            {Math.round(teamBalance)} USDT
          </span>
          <span className={styles.divider} />
          <span className={styles.itemText}>Командный портфель</span>
        </div>
        <div className={styles.item}>
          <span className={styles.itemText}>{level}</span>
          <span className={styles.divider} />
          <span className={styles.itemText}>Карьерный ранг</span>
        </div>
      </div>
      <div className={styles.subWrapper} style={{ flexGrow: 1 }}>
        <div className={styles.item}>
          <span className={styles.itemText}>{totalBonus} USDT</span>
          <span className={styles.divider} />
          <span className={styles.itemText}>Бонус</span>
        </div>
      </div>
    </div>
  );
};
