import { FC, useEffect, useState } from 'react';
import styles from './table.module.scss';
import { useWeb3React } from '@web3-react/core';
import { getPartnersByAccount } from '../../../../../utils/web3/getPartnersByAccount';
import {
  useInvestTokenContract,
  useSaleContract,
} from '../../../../../hooks/web3/useContract';
import { getTableDataByAccount } from '../../../../../utils/web3/getTableDataByAccount';
import { TelegramIcon } from './telegramIcon';
import { getTeamAmount } from '../../../../../utils/web3/getTeamAmount';

type TableProps = object;

// const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

type TableRowData = {
  depth: number;
  partners: string[];
  personalAmount: number;
  teamAmount: number;
  level: number;
  id: string;
  name: string;
  telegram: string | null;
  isExpanded: boolean;
  totalUsers: number;
  structureUsers: number;
  teamBalance: number;
  percent: string | number;
};

export const Table: FC<TableProps> = () => {
  const [tableData, setTableData] = useState<TableRowData[]>([]);
  const { account } = useWeb3React();
  const saleContract = useSaleContract();
  const investTokenContract = useInvestTokenContract();
  const [userTeamBalance, setUserTeamBalance] = useState(0);

  useEffect(() => {
    const init = async () => {
      if (!account || !saleContract) {
        return;
      }

      const partners = await getPartnersByAccount(saleContract, account);
      const newTeamBalance = await getTeamAmount(
        account,
        partners,
        saleContract
      );

      setUserTeamBalance(newTeamBalance);
    };

    init().catch(console.error);
  }, [account, saleContract]);

  useEffect(() => {
    const init = async () => {
      if (
        !account ||
        !saleContract ||
        !investTokenContract ||
        !userTeamBalance
      ) {
        return;
      }
      const firstLevelPartners = await getPartnersByAccount(
        saleContract,
        account
      );
      const partnersDataPromises = firstLevelPartners.map(
        async (parnerAccount) => {
          const tableData = await getTableDataByAccount(
            parnerAccount,
            saleContract,
            investTokenContract
          );
          const teamBalance = await getTeamAmount(
            parnerAccount,
            tableData.partners,
            saleContract
          );

          const balancePerUser = await getTeamAmount(
            account,
            [parnerAccount],
            saleContract
          );
          const percent =
            Math.round((balancePerUser / userTeamBalance) * 10000) / 100;

          return {
            id: parnerAccount,
            ...tableData,
            depth: 0,
            isExpanded: false,
            teamBalance,
            percent,
          };
        }
      );
      const initialTableData = await Promise.all(partnersDataPromises);
      setTableData(initialTableData);
    };

    init().catch(console.error);
  }, [account, saleContract, investTokenContract, userTeamBalance]);

  const handleExpandItem = (id: string) => async () => {
    const item = tableData.find((item) => item.id === id);
    const itemIndex = tableData.findIndex((item) => item.id === id);
    if (!item || !saleContract || !investTokenContract) {
      return;
    }
    const itemPartners = await getPartnersByAccount(saleContract, item.id);
    const partnersDataPromises = itemPartners.map(async (parnerAccount) => {
      const tableData = await getTableDataByAccount(
        parnerAccount,
        saleContract,
        investTokenContract
      );
      const teamBalance = await getTeamAmount(
        parnerAccount,
        tableData.partners,
        saleContract
      );

      const balancePerUser = await getTeamAmount(
        item.id,
        [parnerAccount],
        saleContract
      );
      const percent =
        Math.round((balancePerUser / userTeamBalance) * 10000) / 100;
      return {
        id: parnerAccount,
        ...tableData,
        percent,
        teamBalance,
        depth: item.depth + 1,
        isExpanded: false,
      };
    });
    const partnersData = await Promise.all(partnersDataPromises);

    const dataBeforeItem = tableData.filter((_, index) => index < itemIndex);
    const newItem = { ...item, isExpanded: true };
    const dataAfterItem = tableData.filter((_, index) => index > itemIndex);
    setTableData([
      ...dataBeforeItem,
      newItem,
      ...partnersData,
      ...dataAfterItem,
    ]);
  };

  const handleCloseItem = (id: string) => () => {
    const itemIndex = tableData.findIndex((item) => item.id === id);

    tableData[itemIndex].isExpanded = false;
    let nextItemIndex = tableData.findIndex(
      (item2, index) =>
        item2.depth <= tableData[itemIndex].depth && index > itemIndex
    );

    if (nextItemIndex === -1) {
      nextItemIndex = tableData.length;
    }

    setTableData(
      tableData.filter(
        (_, index) => index <= itemIndex || index >= nextItemIndex
      )
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {/* <div className={styles.levelSelectWrapper}>
          <span>Уровень:</span>
          {levels.map((level) => (
            <div
              className={styles.levelWrapper}
              key={level}
              onClick={() => setActiveLevel(level)}
            >
              <span>{level}</span>
              {activeLevel === level && (
                <div className={styles.levelLowerStroke} />
              )}
            </div>
          ))}
        </div> */}
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div className={styles.tableUpLeftCell}>
              Логин (E-mail) партнера
            </div>
            <div className={styles.tableUpCell}>%</div>
            <div className={styles.tableUpCell}>Активных партнеров</div>
            <div className={styles.tableUpCell}>Личных партнеров</div>
            <div className={styles.tableUpCell}>Личный портфель</div>
            <div className={styles.tableUpCell}>Командный портфель</div>
            <div className={styles.tableUpCell}>Карьерный ранг</div>
          </div>
          {tableData.map((row) => (
            <div className={styles.tableRow} key={row.id}>
              <div
                className={styles.tableFirstCell}
                style={{ paddingLeft: `${row.depth * 30}px` }}
              >
                <span className={styles.cellTop}>
                  <span className={styles.cellLevel}>{row.depth + 1}</span>
                  <div className={styles.cellImageWrapper}></div>
                  <span className={styles.cellPartnerName}>{row.name}</span>
                  <span className={styles.cellPartnerStatus}>Active</span>
                </span>
                <span className={styles.cellBottom}>
                  {(row.partners.length &&
                    (row.isExpanded ? (
                      <button
                        className={styles.cellPartnerButton}
                        onClick={handleCloseItem(row.id)}
                      >
                        Свернуть
                      </button>
                    ) : (
                      <button
                        className={styles.cellPartnerButton}
                        onClick={handleExpandItem(row.id)}
                      >
                        Развернуть
                      </button>
                    ))) ||
                    null}
                  {row.telegram && (
                    <a
                      href={`https://t.me/${row.telegram}`}
                      target="_blank"
                      className={styles.tgButton}
                    >
                      <TelegramIcon />
                    </a>
                  )}
                </span>
              </div>
              <div className={styles.tableCell}>{row.percent}</div>
              <div className={styles.tableCell}>{row.structureUsers}</div>
              <div className={styles.tableCell}>{row.partners.length}</div>
              <div className={styles.tableCell}>
                {/* elchin 1 */}
                {Math.round(row.personalAmount)}
              </div>
              <div className={styles.tableCell}>
                {/* elchin 2 */}
                {Math.round(row.teamAmount)}
              </div>
              <div className={styles.tableCell}>{row.level}</div>
            </div>
          ))}
        </div>
        {/* <button className={styles.showMoreButton}>Показать ещё</button> */}
      </div>
    </div>
  );
};
