import { FC } from 'react';
import styles from './modal.module.scss';
import { Popup } from '../../../../../ui-kit';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const Modal: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Popup isOpen={isOpen} onClose={onClose} contentClassName={styles.modal}>
      <>
        <div className={styles.content}>
          <img
            alt="partners program"
            src="/images/partners-program.png"
            className={styles.screenshot}
          />
        </div>
        <a className={styles.link} href="#">
          Скачть PDF
        </a>
      </>
    </Popup>
  );
};
