'use client';

import { FC, useRef } from 'react';
import styles from './assets.module.scss';
import { useGSAP } from '@gsap/react';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import gsap from 'gsap';

gsap.registerPlugin(ScrollTrigger);

export const Assets: FC = () => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const coinImageRef = useRef<HTMLImageElement>(null);
  const topTextRef = useRef<HTMLParagraphElement>(null);
  const topIconRef = useRef<SVGSVGElement>(null);
  const bottomTextRef = useRef<HTMLParagraphElement>(null);
  const bottomIconRef = useRef<SVGSVGElement>(null);
  const finalTextRef = useRef<HTMLParagraphElement>(null);

  useGSAP(() => {
    const titleTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const coinImageTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const topTextTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const topIconTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const bottomTextTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const bottomIconTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const finalTextTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });

    titleTL.to(titleRef.current, {
      opacity: '1',
      duration: 0.5,
    });
    coinImageTL.to(coinImageRef.current, {
      opacity: '1',
      duration: 0.1,
      delay: 0.5,
    });
    coinImageTL.to(coinImageRef.current, {
      transform: 'scale(1)',
      duration: 0.5,
      delay: 0.1,
    });

    coinImageTL.to(coinImageRef.current, {
      right: '0',
      duration: 0.5,
      delay: 0.6,
    });
    coinImageTL.to(coinImageRef.current, {
      right: 'auto',
      left: '0',
      duration: 0.5,
      delay: 11,
    });

    finalTextTL.to(finalTextRef.current, {
      opacity: '1',
      duration: 0.5,
      delay: 15,
    });

    topIconTL.to(topIconRef.current, {
      opacity: '1',
      duration: 0.5,
      delay: 3,
    });
    bottomIconTL.to(bottomIconRef.current, {
      opacity: '1',
      duration: 0.5,
      delay: 3.5,
    });
    topTextTL.to(topTextRef.current, {
      opacity: '1',
      duration: 0.5,
      delay: 4,
    });
    bottomTextTL.to(bottomTextRef.current, {
      opacity: '1',
      duration: 0.5,
      delay: 4.5,
    });

    topIconTL.to(topIconRef.current, {
      opacity: '0',
      duration: 0.5,
      delay: 8,
    });
    bottomIconTL.to(bottomIconRef.current, {
      opacity: '0',
      duration: 0.5,
      delay: 8,
    });
    topTextTL.to(topTextRef.current, {
      opacity: '0',
      duration: 0.5,
      delay: 8,
    });
    bottomTextTL.to(bottomTextRef.current, {
      opacity: '0',
      duration: 0.5,
      delay: 8.5,
    });
  });

  return (
    <div className={styles.wrap}>
      <div className={styles.inner} ref={wrapRef}>
        <h2 className={styles.title} ref={titleRef}>
          <span className={styles.titleTop}>Активы холдинга</span>
          <span className={styles.titleBottom}>токенизированы</span>
        </h2>
        <div className={styles.content}>
          <p className={styles.finalText} ref={finalTextRef}>
            Мы предлагаем устойчивую финансовую модель, позволяющую инвесторам
            чувствовать себя безопасно благодаря системе обратного выкупа их
            токенов с прибыли от реальной деятельности компании.
          </p>
          <picture>
            <source srcSet="/images/main/assets/token.webp" />
            <img
              src="/images/main/assets/token.png"
              alt="token"
              className={styles.tokenImage}
              ref={coinImageRef}
            />
          </picture>
          <div className={styles.firstContent}>
            <div className={styles.firstBlockTop}>
              <p className={styles.firstBlockTopText} ref={topTextRef}>
                Это делает возможным сотрудничество по всему миру
              </p>
              <svg
                width="400"
                height="189"
                viewBox="0 0 400 189"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.firstBlockTopLine}
                ref={topIconRef}
              >
                <path
                  d="M1 0V148C1 170.091 18.9086 188 41 188H400"
                  stroke="url(#paint0_linear_784_8210)"
                  strokeOpacity="0.5"
                  strokeWidth="2"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_784_8210"
                    x1="244.174"
                    y1="179"
                    x2="-34.4393"
                    y2="59.3477"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopOpacity="0" />
                    <stop offset="0.385" stopColor="white" />
                    <stop offset="0.635" stopColor="white" />
                    <stop offset="1" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className={styles.firstBlockBottom}>
              <svg
                width="249"
                height="195"
                viewBox="0 0 249 195"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                ref={bottomIconRef}
                className={styles.firstBlockBottomLine}
              >
                <path
                  d="M249 1L41 1.00001C18.9086 1.00001 0.999986 18.9086 0.999986 41L0.999993 195"
                  stroke="url(#paint0_linear_784_8211)"
                  strokeOpacity="0.5"
                  strokeWidth="2"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_784_8211"
                    x1="12.8723"
                    y1="119.235"
                    x2="37.6493"
                    y2="-37.2936"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopOpacity="0" />
                    <stop offset="0.385" stopColor="white" />
                    <stop offset="0.635" stopColor="white" />
                    <stop offset="1" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
              <p className={styles.firstBlockBottomText} ref={bottomTextRef}>
                И&nbsp;обеспечивает полную прозрачность взаимоотношений.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
