import { FC } from 'react';
import styles from './tokenInfo.module.scss';
import { UserInfo } from '../../../../../../api/types';

type TokenInfoProps = {
  balanceOf: number;
  usdtBalance: number;
  userInfo: UserInfo | null;
};

export const TokenInfo: FC<TokenInfoProps> = ({ balanceOf, usdtBalance, userInfo }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img className={styles.headerImage} alt="logo" src="/images/logo.png" />
        <span className={styles.headerTitle}>Lime tokens</span>
      </div>
      <div className={styles.balanceTable}>
        <div className={styles.balanceTableColumn}>
          <span>Доступный баланс</span>
          <span>Инвестировано</span>
          <span>Текущий баланс</span>
        </div>
        <div className={styles.balanceTableColumn}>
          <span className={styles.balanceTableValue}>{balanceOf}</span>
          <span className={styles.balanceTableValue}>
            {((userInfo?.invest_amount ?? 0) / 10 ** 18).toFixed(2)}
          </span>
          <span className={styles.balanceTableValue}>{usdtBalance}</span>
        </div>
        <div className={styles.balanceTableColumn}>
          <span className={styles.balanceTableValue}>LMT</span>
          <span className={styles.balanceTableValue}>USDT</span>
          <span className={styles.balanceTableValue}>USDT</span>
        </div>
      </div>
    </div>
  );
};
