import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { useConnect } from './web3/useConnect';

export const useForceAccount = () => {
  const { account } = useWeb3React();
  const { handleConnect } = useConnect();

  useEffect(() => {
    const init = async () => {
      const connector = localStorage.getItem('connector') as
        | 'Metamask'
        | 'WalletConnect';
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      if (account || !connector) {
        return;
      }
      try {
        await handleConnect(connector);
      } catch (error) {
        console.log(error);
      }
    };
  }, [account]);
};
