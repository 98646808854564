import { Contract } from 'ethers';

export const getPartnersByAccount = async (
  saleContract: Contract | null,
  account: string
): Promise<string[]> => {
  if (!saleContract) {
    return [];
  }
  const result: string[] = [];
  let current = 0;
  while (true) {
    try {
      const data = await saleContract.getFunction('firstLine')(
        account,
        current
      );
      if (!data) {
        break;
      }
      current++;
      result.push(data as string);
    } catch (_e) {
      break;
    }
  }
  return result;
};
