import React from 'react';

export const useOnClickOutside = (
  ref: React.RefObject<HTMLDivElement>,
  callback: { (): void; (): void }
) => {
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
