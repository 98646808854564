import { Header } from '../../components/profile';
import { Partners } from '../../components/profile/subpages/partners';
import { useForceAccount } from '../../hooks/useForceAccount';
import styles from './partners.module.scss';

export function PartnersPage() {
  useForceAccount();

  return (
    <>
      <Header />
      <main className={styles.main}>
        <Partners />
      </main>
    </>
  );
}
