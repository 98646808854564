import { Contract } from 'ethers';
import { getUserLevel } from './getUserLevel';
import { getPartnersByAccount } from './getPartnersByAccount';
import { getUserInvestTokenBalance } from './getUserInvestTokenBalance';
import { getTeamAmount } from './getTeamAmount';
import { getPartnerInfo } from './getPartnerInfo';
import { getUserInfo } from '../../api';
import { getSpendUsdtAmount } from './getSpendUsdtAmount';

export const getTableDataByAccount = async (
  account: string,
  saleContract: Contract,
  investTokenContract: Contract
) => {
  const level = await getUserLevel(saleContract, account);
  const partners = await getPartnersByAccount(saleContract, account);
  const personalAmount = await getSpendUsdtAmount(saleContract, account);
  // const price = await
  const teamAmount = await getTeamAmount(account, partners, saleContract);
  const { name, telegram } = await getPartnerInfo(account);
  const { invest_amount, total_users, structure_users } = await getUserInfo(
    account
  );

  return {
    partners,
    personalAmount,
    totalUsers: total_users,
    structureUsers: structure_users,
    teamAmount,
    level,
    name,
    telegram,
  };
};
