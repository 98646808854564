const CHAIN_ID = process.env.REACT_APP_CHAIN_ID ?? '';

export const BSCTestnet = {
  chainId: 97,
  chainName: 'Smart Chain - Testnet',
  chainIdString: `0x${(97).toString(16)}`,
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18 as const,
  },
  bscApiEndpoint: 'https://api-testnet.bscscan.com/api',
  rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  blockExplorerUrls: ['https://testnet.bscscan.com'],
};

export const BSC = {
  chainId: 56,
  chainName: 'Smart Chain',
  chainIdString: `0x${(56).toString(16)}`,
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18 as const,
  },
  bscApiEndpoint: 'https://api.bscscan.com/api',
  rpcUrls: ['https://bsc-dataseed.bnbchain.org'],
  blockExplorerUrls: ['https://bscscan.com'],
};

type ChainType = typeof BSCTestnet | typeof BSC;

const CHAINS: Record<string, ChainType> = {
  [BSCTestnet.chainId]: BSCTestnet,
  [BSC.chainId]: BSC,
};

export const chain = CHAINS[CHAIN_ID];

export const chainParams = {
  chainId: chain?.chainIdString,
  chainName: chain?.chainName,
  nativeCurrency: chain?.nativeCurrency,
  rpcUrls: chain.rpcUrls,
  blockExplorerUrls: chain?.blockExplorerUrls,
};
