import { FC } from 'react';

export const CopyIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
    >
      <rect x="9" y="5" width="8" height="9" rx="1" fill="#D9D9D9" />
      <rect x="0.5" y="0.5" width="22" height="23" rx="1.5" stroke="white" />
      <rect x="5" y="10" width="8" height="9" rx="1" fill="#D9D9D9" />
    </svg>
  );
};
