'use client';

import { FC, FormEvent, useEffect, useState } from 'react';
import styles from './tradeModal.module.scss';
import { Popup } from '../../../../../../ui-kit';
import { useWeb3React } from '@web3-react/core';
import { getRateIn } from '../../../../../../utils/web3/getRateIn';
import {
  useInvestTokenContract,
  usePriceRateContract,
} from '../../../../../../hooks/web3/useContract';
import { getRateOut } from '../../../../../../utils/web3/getRateOut';
import { Oval } from 'react-loader-spinner';
import cn from 'classnames';
import { UserInfo } from '../../../../../../api/types';
import { getUserInfo } from '../../../../../../api';
import { getUserInvestTokenBalance } from '../../../../../../utils/web3/getUserInvestTokenBalance';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (amount: number) => Promise<void>;
}

export const SellModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  const [tokensValue, setTokensValue] = useState(0);
  const [tokensPrice, setTokensPrice] = useState(0);
  const { account } = useWeb3React();
  const priceRateContract = usePriceRateContract();
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const investTokenContract = useInvestTokenContract();
  const [balanceOf, setBalanceOf] = useState(0);

  useEffect(() => {
    const init = async () => {
      if (!investTokenContract || !priceRateContract || !account) {
        return;
      }
      const newBalanceOf = await getUserInvestTokenBalance(
        investTokenContract,
        account
      );
      setBalanceOf(newBalanceOf);
    };

    init().catch(console.error);
  }, [investTokenContract]);

  const recalcPrice = async (tokenCount: number) => {
    if (!priceRateContract) {
      return;
    }
    const newTokenPrice = await getRateOut(priceRateContract, tokenCount);
    setTokensPrice(newTokenPrice);
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!value) {
      setTokensValue(0);
    }

    if (isNaN(+value)) {
      return;
    }
    const newValue = Number(value);

    setTokensValue(newValue);
    recalcPrice(newValue).catch(console.error);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoaderVisible(true);
    await onSubmit(tokensValue);
    setLoaderVisible(false);
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={styles.modalContent}
    >
      <>
        <div className={styles.header}>
          <span className={styles.headerTitle}>Кошелек:</span>
          {/* TODO кошелек */}
          <span className={styles.wallet}>{account}</span>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.valueBlock}>
            <span className={styles.valueTitle}>Количество токенов:</span>
            <input
              className={styles.valueInput}
              placeholder="0"
              value={tokensValue}
              onChange={handleChangeValue}
            />
          </div>
          {/* TODO Процент доступного */}
          <span className={styles.available}>
            Доступно 10% от&nbsp;текущего остатка
          </span>
          <div className={styles.tokenInfo}>
            <div className={styles.tokenInfoLine}>
              <span className={styles.tokenInfoQntTitle}>Баланс:</span>
              <span className={styles.tokenInfoQnt}>{balanceOf}&nbsp;LMT</span>
            </div>
            <div className={styles.tokenInfoLine}>
              <span className={styles.tokenInfoValueTitle}>К получению:</span>
              {/* TODO к оплате и сконвертированное значение */}
              <span className={styles.tokenInfoValue}>
                <span>{tokensPrice}&nbsp;USDT</span>
                {/* <span className={styles.convertValue}>≈ 10.111&nbsp;RUB</span> */}
              </span>
            </div>
          </div>
          <button type="submit" className={styles.submitBtn}>
            ПРОДАТЬ
          </button>
        </form>
        <p className={styles.agree}>
          Покупая продукт, вы соглашаетесь с{' '}
          <a href="https://limevers.gitbook.io/ime-technologies/10.-disclaimer">
            инвестиционными рисками
          </a>
          .
        </p>
        <div
          className={cn(styles.loaderWrapper, {
            [styles.loaderWrapperInactive]: !loaderVisible,
          })}
        >
          <Oval
            visible={loaderVisible}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </>
    </Popup>
  );
};

export const BuyModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  const { account } = useWeb3React();
  const [tokensValue, setTokensValue] = useState(0);
  const [tokensPrice, setTokensPrice] = useState(0);
  const priceRateContract = usePriceRateContract();
  const [loaderVisible, setLoaderVisible] = useState(false);

  const recalcPrice = async (tokenCount: number) => {
    if (!priceRateContract) {
      return;
    }
    const newTokenPrice = await getRateIn(priceRateContract, tokenCount);
    setTokensPrice(newTokenPrice);
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!value) {
      setTokensValue(0);
    }

    if (isNaN(+value)) {
      return;
    }

    const newValue = Number(value);
    setTokensValue(newValue);

    recalcPrice(newValue).catch(console.error);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoaderVisible(true);
    await onSubmit(tokensValue);
    setLoaderVisible(false);
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={styles.modalContent}
    >
      <>
        <div className={styles.header}>
          <span className={styles.headerTitle}>Кошелек:</span>
          {/* TODO кошелек */}
          <span className={styles.wallet}>{account}</span>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.valueBlock}>
            <span className={styles.valueTitle}>
              Количество токенов (USDT):
            </span>
            <input
              className={styles.valueInput}
              placeholder="0"
              value={tokensValue}
              onChange={handleChangeValue}
            />
          </div>
          <div className={styles.tokenInfo}>
            <div className={styles.tokenInfoLine}>
              <span className={styles.tokenInfoQntTitle}>
                Количество токенов:
              </span>
              <span className={styles.tokenInfoQnt}>
                {tokensPrice}&nbsp;LMT
              </span>
            </div>
            <div className={styles.tokenInfoLine}>
              <span className={styles.tokenInfoValueTitle}>К оплате:</span>
              {/* TODO к оплате и сконвертированное значение */}
              <span className={styles.tokenInfoValue}>
                <span>{tokensValue}&nbsp;USDT</span>
              </span>
            </div>
          </div>
          <button type="submit" className={styles.submitBtn}>
            КУПИТЬ
          </button>
        </form>
        <p className={styles.agree}>
          Покупая продукт, вы соглашаетесь с{' '}
          <a href="https://limevers.gitbook.io/ime-technologies/10.-disclaimer">
            инвестиционными рисками
          </a>
          .{' '}
        </p>
        <div
          className={cn(styles.loaderWrapper, {
            [styles.loaderWrapperInactive]: !loaderVisible,
          })}
        >
          <Oval
            visible={loaderVisible}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </>
    </Popup>
  );
};
