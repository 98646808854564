import { ChangeEvent, FC, useEffect, useState } from 'react';
import styles from './kyc.module.scss';
import { updateOrCreateUser } from '../../../../api';
import { useWeb3React } from '@web3-react/core';
import { useKyc } from './kyc.hook';

export const Kyc: FC = () => {
  const { account } = useWeb3React();
  const { userData } = useKyc(account);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [telegram, setTelegram] = useState('');
  const [wallet, setWallet] = useState('');

  useEffect(() => {
    setWallet(account ?? '');
  }, [account]);

  useEffect(() => {
    if (!userData) {
      return;
    }
    setFirstName(userData?.first_name ?? '');
    setLastName(userData?.last_name ?? '');
    setEmail(userData?.email ?? '');
    setTelegram(userData?.telegram ?? '');
    setWallet(userData?.wallet ?? '');
  }, [userData]);

  const onChangeField =
    (setter: (value: string) => void) => (e: ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value || '');
    };

  const onSubmit = () => {
    updateOrCreateUser({
      first_name: firstName,
      last_name: lastName,
      email,
      telegram: telegram || '',
      wallet,
    }).catch(console.error);
  };

  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.photoWrapper}>
        <div className={styles.photoPlaceHolder}>
          <button className={styles.uploadPhotoBtn}>Загрузить фото</button>
        </div>
      </div> */}
      <div className={styles.formBlockWrapper}>
        <div className={styles.formBlock}>
          <span className={styles.formBlockHeader}>KYC данные</span>
          <input
            className={styles.formBlockInput}
            onChange={onChangeField(setLastName)}
            value={lastName}
            placeholder="Фамилия"
          ></input>
          <input
            className={styles.formBlockInput}
            onChange={onChangeField(setFirstName)}
            value={firstName}
            placeholder="Имя"
          ></input>
          <input
            className={styles.formBlockInput}
            onChange={onChangeField(setEmail)}
            value={email}
            placeholder="Email"
          ></input>
          <input
            className={styles.formBlockInput}
            placeholder="Telegram"
            value={telegram}
            onChange={onChangeField((value: string) => {
              setTelegram(
                value.replace('@', '').length > 0
                  ? `@${value.replace('@', '')}`
                  : ''
              );
            })}
          ></input>
          {/* </div>
        <div className={styles.formBlock}> */}
          <span className={styles.formBlockHeader}>Кошелек</span>
          <input
            disabled
            className={styles.formBlockInput}
            placeholder="Tether TRC"
            value={wallet}
            onChange={onChangeField(setWallet)}
          ></input>
          <button className={styles.submitBtn} onClick={onSubmit}>
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
};
