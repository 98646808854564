import { Contract } from 'ethers';

export const getUserInvestTokenBalance = async (
  investTokenContract: Contract,
  account: string
) => {
  const newBalanceOf =
    ((await investTokenContract.getFunction('balanceOf')(account)) as bigint) /
    BigInt('1000000000000000000'); // 10 ** 18
  return Number(newBalanceOf);
};
