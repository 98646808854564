import { Contract } from 'ethers';

export const getUserInvider = async (
  saleContract: Contract,
  account: string
) => {
  try {
    const data = await saleContract.getFunction('_inviter')(account);
    return data as string;
  } catch (e) {
    console.error(e);
    return '';
  }
};
