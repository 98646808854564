export const saleAbi = [{
    "inputs": [{"internalType": "address", "name": "usdtAddress", "type": "address"}],
    "stateMutability": "nonpayable",
    "type": "constructor"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "uint256",
        "name": "usdtAmount",
        "type": "uint256"
    }, {"indexed": false, "internalType": "uint256", "name": "tokensAmount", "type": "uint256"}],
    "name": "BUY",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{"indexed": false, "internalType": "uint256", "name": "tokensAmount", "type": "uint256"}],
    "name": "EXCHANGE",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
    }, {"indexed": true, "internalType": "address", "name": "newOwner", "type": "address"}],
    "name": "OwnershipTransferred",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "uint256",
        "name": "usdtAmount",
        "type": "uint256"
    }, {"indexed": false, "internalType": "uint256", "name": "tokensAmount", "type": "uint256"}],
    "name": "SELL",
    "type": "event"
}, {
    "inputs": [],
    "name": "SALE_PERCENT",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "_bonusesContract",
    "outputs": [{"internalType": "contract BonusesContractInterface", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "", "type": "address"}],
    "name": "_inviter",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "", "type": "address"}],
    "name": "_lastBuyTime",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "_priceRate",
    "outputs": [{"internalType": "contract PriceRateInterface", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "_rate",
    "outputs": [{"internalType": "uint32", "name": "", "type": "uint32"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "_rewardToken",
    "outputs": [{"internalType": "contract IERC20", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "_tokenForSale",
    "outputs": [{"internalType": "contract IERC20", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "", "type": "address"}],
    "name": "_totalSpendUsdt",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "_usdtContract",
    "outputs": [{"internalType": "contract IERC20", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "user", "type": "address"}],
    "name": "buyBackTime",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "inAmount", "type": "uint256"}, {
        "internalType": "address",
        "name": "refAddress",
        "type": "address"
    }], "name": "buyToken", "outputs": [], "stateMutability": "nonpayable", "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "inAmount", "type": "uint256"}],
    "name": "calculateIn",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "", "type": "address"}, {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "name": "firstLine",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "_inviterAddress", "type": "address"}, {
        "internalType": "address",
        "name": "_userAddress",
        "type": "address"
    }],
    "name": "getUserFirstLineAmount",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "_userAddress", "type": "address"}],
    "name": "getUserLevel",
    "outputs": [{
        "components": [{
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "teamAmount", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "bonusAmount",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "level", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "refPercent",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "nextLevel", "type": "uint256"}],
        "internalType": "struct SaleLime.level",
        "name": "",
        "type": "tuple"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "name": "levelsArray",
    "outputs": [{"internalType": "uint256", "name": "amount", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "teamAmount",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "bonusAmount", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "level",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "refPercent", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "nextLevel",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "owner",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amount", "type": "uint256"}],
    "name": "returnTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "_bonusesContractAddress", "type": "address"}],
    "name": "setBonusesContract",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "user", "type": "address"}, {
        "internalType": "uint256",
        "name": "levelId",
        "type": "uint256"
    }], "name": "setLevel", "outputs": [], "stateMutability": "nonpayable", "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "rateAddress", "type": "address"}],
    "name": "setRateContract",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "rewardTokenAddress", "type": "address"}],
    "name": "setRewardToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "newPercent", "type": "uint256"}],
    "name": "setSalePercent",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "sellTokenAddress", "type": "address"}],
    "name": "setTokenForSale",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amount", "type": "uint256"}],
    "name": "swapTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "newOwner", "type": "address"}],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "", "type": "address"}, {
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "name": "userFirstLineAmount",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "", "type": "address"}],
    "name": "userLevel",
    "outputs": [{"internalType": "uint256", "name": "amount", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "teamAmount",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "bonusAmount", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "level",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "refPercent", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "nextLevel",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "_tokenAddress", "type": "address"}, {
        "internalType": "address",
        "name": "_transferTo",
        "type": "address"
    }, {"internalType": "uint256", "name": "_tokenAmount", "type": "uint256"}],
    "name": "withdraw",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}];
