import { Contract } from 'ethers';

export const buyInvestToken = async (
  amount: number,
  refAddress: string,
  saleContract: Contract
) => {
  const bigIntAmount = BigInt(amount) * BigInt(10 ** 18);

  await saleContract.getFunction('buyToken')(bigIntAmount, refAddress);
};
