import cn from 'classnames';
import { type FC, type ReactNode } from 'react';
import styles from './button.module.scss';
import { GreenLineIcon } from '../../components/icons';
import { Link } from 'react-router-dom';

export enum ButtonType {
  SUBMIT = 'submit',
  RESET = 'reset',
  BUTTON = 'button',
}

export enum ButtonStyle {
  MAIN = 'main',
}

export enum ButtonSize {
  SM = 'sm',
  LG = 'lg',
  MD = 'md',
  XS = 'xs',
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  type?: ButtonType;
  buttonStyle?: ButtonStyle;
  buttonSize?: ButtonSize;
  className?: string;
}

interface LinkProps extends React.HTMLProps<HTMLAnchorElement> {
  children?: ReactNode;
  buttonStyle?: ButtonStyle;
  buttonSize?: ButtonSize;
  className?: string;
  href: string;
}

export const Button: FC<ButtonProps> = ({
  type = ButtonType.BUTTON,
  buttonStyle = ButtonStyle.MAIN,
  buttonSize = ButtonSize.MD,
  children,
  className,
  ...props
}) => {
  return (
    <button
      className={cn(
        styles.btn,
        styles[buttonStyle],
        styles[buttonSize],
        className
      )}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};

export const StyledLink: FC<LinkProps> = ({
  href,
  buttonStyle = ButtonStyle.MAIN,
  buttonSize = ButtonSize.SM,
  children,
  className,
  ...props
}) => {
  return (
    <Link
      className={cn(
        styles.btn,
        styles[buttonStyle],
        styles[buttonSize],
        className
      )}
      to={href}
      {...props}
    >
      {children}
    </Link>
  );
};
