'use client';

import { useState, useEffect } from 'react';

import { useWeb3React } from '@web3-react/core';
import { Contract, Interface, ContractRunner } from 'ethers';
import {
  BONUSES_CONTRACT_ADDRESS,
  INVEST_TOKEN_CONTRACT_ADDRESS,
  PRICE_RATE_CONTRACT_ADDRESS,
  REWARD_TOKEN_CONTRACT_ADDRESS,
  SALE_CONTRACT_ADDRESS,
  TEST_USDT_CONTRACT_ADDRESS,
} from '../../web3/consts';
import { bonusesAbi } from '../../web3/abi/bonuses';
import { investTokenAbi } from '../../web3/abi/investToken';
import { saleAbi } from '../../web3/abi/sale';
import { rewardTokenAbi } from '../../web3/abi/rewardToken';
import { testUsdtAbi } from '../../web3/abi/testUsdt';
import { priceRateAbi } from '../../web3/abi/priceRate';

function useContract(address: string, abi: unknown) {
  const { provider, account } = useWeb3React();
  const [contract, setContract] = useState<Contract | null>(null);

  useEffect(() => {
    // console.log('provider', provider, 'account', account);

    if (!provider?.getSigner()) {
      // console.log('!provider?.getSigner()');

      return;
    }
    setContract(
      new Contract(
        address,
        abi as Interface,
        provider.getSigner() as unknown as ContractRunner
      )
    );
  }, [provider, account, address, abi]);

  return contract;
}

export const useBonusesContract = () =>
  useContract(BONUSES_CONTRACT_ADDRESS, bonusesAbi);

export const useInvestTokenContract = () =>
  useContract(INVEST_TOKEN_CONTRACT_ADDRESS, investTokenAbi);

export const useSaleContract = () =>
  useContract(SALE_CONTRACT_ADDRESS, saleAbi);

export const useRewardTokenContract = () =>
  useContract(REWARD_TOKEN_CONTRACT_ADDRESS, rewardTokenAbi);

export const useTestUsdtContract = () =>
  useContract(TEST_USDT_CONTRACT_ADDRESS, testUsdtAbi);

export const usePriceRateContract = () =>
  useContract(PRICE_RATE_CONTRACT_ADDRESS, priceRateAbi);
