import { Header } from '../../components/profile';
import { History } from '../../components/profile/subpages/history';
import { useForceAccount } from '../../hooks/useForceAccount';
import styles from './history.module.scss';

export function HistoryPage() {
  useForceAccount();

  return (
    <>
      <Header />
      <main className={styles.main}>
        <History />
      </main>
    </>
  );
}
