'use client';

import { FC, useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { PriceGraphPoint } from '../../../../../../api/types';

type ChartProps = {
  data: PriceGraphPoint[];
};

export const Chart: FC<ChartProps> = ({ data }) => {
  const chartHeight = useMemo(() => {
    const maxHeight = Math.max(...data.map((el) => el.price));
    return Math.floor(maxHeight * 1.5);
  }, [data]);

  const minChartVal = useMemo(() => {
    return Math.min(...data.map((el) => el.price));
  }, [data]);

  const mappedData = useMemo(() => {
    return data.map((el) => ({
      ...el,
      parsedDate: new Date(el.time * 1000).toLocaleDateString('ru-RU'),
    }));
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        data={mappedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="parsedDate" />
        <YAxis
          domain={[minChartVal, chartHeight]}
          tickFormatter={(val) => (val as number).toFixed(4)}
        />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="price"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
