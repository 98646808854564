'use client';

import { FC, useEffect, useRef, useState } from 'react';
import styles from './cabinet.module.scss';
import { TokenInfo } from './subcomponents/tokenInfo';
import { PartnerLinkBlock } from './subcomponents/partnerLinkBlock';
import { Graphic } from './subcomponents/graphic';
import { BuyModal, SellModal } from './subcomponents/tradeModal';
import { useWeb3React } from '@web3-react/core';
import {
  useInvestTokenContract,
  usePriceRateContract,
  useSaleContract,
  useTestUsdtContract,
} from '../../../../hooks/web3/useContract';
import { getUserInvestTokenBalance } from '../../../../utils/web3/getUserInvestTokenBalance';
import { getUsdtBalance } from '../../../../utils/web3/getUsdtBalance';
import { buyInvestToken } from '../../../../utils/web3/buyInvestToken';
import { getAllowance } from '../../../../utils/web3/getAllowance';
import { increaseAllowance } from '../../../../utils/web3/increaseAllowance';
import { SALE_CONTRACT_ADDRESS } from '../../../../web3/consts';
import { sellInvestToken } from '../../../../utils/web3/sellInvestToken';
import { getUserInfo } from '../../../../api';
import { UserInfo } from '../../../../api/types';

type CabinetProps = object;

export const Cabinet: FC<CabinetProps> = () => {
  const { account = '' } = useWeb3React();
  const investTokenContract = useInvestTokenContract();
  const priceRateContract = usePriceRateContract();
  const saleContract = useSaleContract();
  const testUsdtContract = useTestUsdtContract();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  const link = `${window.location.origin}?refId=${account}`;

  const [isOpenBuyModal, setOpenBuyModal] = useState(false);
  const [isOpenSellModal, setOpenSellModal] = useState(false);
  const [balanceOf, setBalanceOf] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);

  useEffect(() => {
    const init = async () => {
      if (!investTokenContract || !priceRateContract) {
        return;
      }
      const newBalanceOf = await getUserInvestTokenBalance(
        investTokenContract,
        account
      );

      const newUsdtBalance = await getUsdtBalance(
        newBalanceOf,
        priceRateContract
      );

      if (newBalanceOf === balanceOf && newUsdtBalance === usdtBalance) {
        return;
      }
      setUsdtBalance(newUsdtBalance);
      setBalanceOf(newBalanceOf);

      const newUserIngo = await getUserInfo(account);
      setUserInfo(newUserIngo);
    };

    init().catch(console.error);
    const intervalId = setInterval(() => {
      init().catch(console.error);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [investTokenContract]);

  const disableModalCloseRef = useRef(false);
  const toggleDisableModalClose = () => {
    disableModalCloseRef.current = true;
    setTimeout(() => {
      disableModalCloseRef.current = false;
    }, 100);
  };

  const handleOpenBuyModal = () => {
    toggleDisableModalClose();
    setOpenBuyModal(true);
  };

  const handleOpenSellModal = () => {
    toggleDisableModalClose();
    setOpenSellModal(true);
  };

  const handleCloseBuyModal = () => {
    if (disableModalCloseRef.current) {
      return;
    }
    setOpenBuyModal(false);
  };

  const handleCloseSellModal = () => {
    if (disableModalCloseRef.current) {
      return;
    }
    setOpenSellModal(false);
  };

  const handleBuyToken = async (amount: number) => {
    if (!saleContract || !testUsdtContract || !account) {
      return;
    }
    const allowance = await getAllowance(
      testUsdtContract,
      account,
      SALE_CONTRACT_ADDRESS
    );
    const requiredAllowance =
      BigInt(amount) * BigInt(10 ** 18) + BigInt(10 ** 18);

    if (requiredAllowance > allowance) {
      await increaseAllowance(
        testUsdtContract,
        SALE_CONTRACT_ADDRESS,
        requiredAllowance - allowance
      ).catch(console.error);
    }
    // todo remove account
    const refAddress = localStorage.getItem('refId') || account;

    if (!refAddress) {
      alert('Не найден пригласивший вас пользователь');
      return;
    }
    await buyInvestToken(amount, refAddress, saleContract).catch(console.error);
    handleCloseBuyModal();
  };

  const handleSellToken = async (amount: number) => {
    if (!saleContract) {
      return;
    }
    await sellInvestToken(amount, saleContract).catch(console.error);
    handleCloseSellModal();
  };

  return (
    <div className={styles.pageWrapper}>
      <BuyModal
        isOpen={isOpenBuyModal}
        onClose={handleCloseBuyModal}
        onSubmit={handleBuyToken}
      />

      <SellModal
        isOpen={isOpenSellModal}
        onClose={handleCloseSellModal}
        onSubmit={handleSellToken}
      />
      <div className={styles.leftPagePart}>
        <TokenInfo
          balanceOf={balanceOf}
          usdtBalance={usdtBalance}
          userInfo={userInfo}
        />
        <PartnerLinkBlock
          handleOpenBuyModal={handleOpenBuyModal}
          handleOpenSellModal={handleOpenSellModal}
          link={link}
        />
      </div>
      <div className={styles.rightPagePart}>
        <Graphic
          handleOpenBuyModal={handleOpenBuyModal}
          handleOpenSellModal={handleOpenSellModal}
        />
      </div>
    </div>
  );
};
