import { Header } from '../../components/profile';
import { Kyc } from '../../components/profile/subpages/kyc';
import { useForceAccount } from '../../hooks/useForceAccount';
import styles from './kyc.module.scss';

export function KycPage() {
  useForceAccount();

  return (
    <>
      <Header />
      <main className={styles.main}>
        <Kyc />
      </main>
    </>
  );
}
