'use client';

import { FC, useRef } from 'react';
import cn from 'classnames';
import styles from './menu.module.scss';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { Link, useNavigate } from 'react-router-dom';
import { useConnect } from '../../../hooks/web3/useConnect';
import { Button } from '../../../ui-kit';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export const Menu: FC<Props> = ({ isOpen, handleClose }) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapRef, handleClose);
  const { handleDisconnect } = useConnect();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    await handleDisconnect();
    navigate('/');
  };

  return (
    <div className={cn(styles.overlay, { [styles.overlayActive]: isOpen })}>
      <div className={styles.wrap} ref={wrapRef}>
        <nav className={styles.nav}>
          <Link to="/profile" className={styles.navLink}>
            Кабинет инвестора
          </Link>
          <Link to="/profile/history" className={styles.navLink}>
            История операций
          </Link>
          <Link to="/profile/partners" className={styles.navLink}>
            Мои партнеры
          </Link>
          <Link to="/profile/kyc" className={styles.navLink}>
            KYC
          </Link>
        </nav>
        <Button className={styles.connectBtn} onClick={handleLogOut}>
          Выйти
        </Button>
      </div>
    </div>
  );
};
