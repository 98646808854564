'use client';

import { FC, useEffect, useState } from 'react';
import styles from './history.module.scss';
import { getTransactionsByAddress } from '../../../../api';
import { useWeb3React } from '@web3-react/core';
import { BackendTransaction } from '../../../../api/types';

export const History: FC = () => {
  const [transactions, setTransactions] = useState<BackendTransaction[]>([]);
  const { account } = useWeb3React();

  useEffect(() => {
    const init = async () => {
      if (!account) {
        return;
      }
      const data = await getTransactionsByAddress(account);
      setTransactions(data);
    };

    init().catch(console.log);
  }, []);

  const parseTransactionAmount = (transactionAmount: number) => {
    if (transactionAmount > 0) {
      return Math.round(transactionAmount / 10 ** 18);
    }
    console.log('transactionAmount', { transactionAmount });
    return Math.round(-transactionAmount / 5 / 10 ** 18);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.tableWrapper}>
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div className={styles.tableRow}>
              <span className={styles.tableCell}>Транзакция №</span>
              <span className={styles.tableCell}>Кол-во</span>
              <span className={styles.tableCell}>Криптоактив (Валюта)</span>
              <span className={styles.tableCell}>В Lime</span>
              <span className={styles.tableCell}>Статус</span>
            </div>
          </div>
          {transactions.map((transaction) => (
            <div className={styles.tableRow} key={transaction.id}>
              <span className={styles.tableCell}>
                <a
                  href={`https://bscscan.com/tx/${transaction.txID}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {transaction.txID}
                </a>
              </span>
              <span className={styles.tableCell}>
                {parseTransactionAmount(transaction.amount)}
              </span>
              <span className={styles.tableCell}>Tether (USDT)</span>
              <span className={styles.tableCell}>{transaction.trx_type}</span>
              <span className={styles.tableCell}>Finished</span>
            </div>
          ))}
        </div>
      </div>
      {/* <button className={styles.showMoreButton}>Показать еще</button> */}
    </div>
  );
};
