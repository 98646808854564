import { Contract } from 'ethers';

export const getUsdtBalance = async (
  investTokenBalance: number,
  priceRateContract: Contract
) => {
  const price = (await priceRateContract.getFunction(
    'getCurrentPrice'
  )()) as bigint;
  const usdtBalance = (BigInt(investTokenBalance) * price) / BigInt(10 ** 18);
  return Number(usdtBalance);
};
