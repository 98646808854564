import { getUser } from '../../api';

const generateName = (
  firstName: string | null,
  lastName: string | null,
  address: string
) => {
  return [firstName, lastName].filter(Boolean).join(' ') || address;
};

export const getPartnerInfo = async (address: string) => {
  try {
    const data = await getUser(address);
    return {
      name: generateName(data.first_name, data.last_name, address),
      telegram: data.telegram,
    };
  } catch (e) {
    console.error(e);
    return {
      name: address,
      telegram: null,
    };
  }
};
