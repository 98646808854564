'use client';

import { Link, useNavigate } from 'react-router-dom';
import { FC, useRef, useState } from 'react';
import styles from './header.module.scss';
import { Button, StyledLink } from '../../../ui-kit';
import cn from 'classnames';
import { Menu } from '../menu/menu';
import { ProviderSelect } from '../providerSelect/providerSelect';
import { useWeb3React } from '@web3-react/core';

type HeaderProps = {
  handleOpenProvider: VoidFunction;
  isProviderSelectOpen: boolean;
  handleCloseProvider: VoidFunction;
};

export const Header: FC<HeaderProps> = ({
  handleOpenProvider,
  handleCloseProvider,
  isProviderSelectOpen,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const freezeMenuCloseRef = useRef(false);

  const { account } = useWeb3React();

  const navigate = useNavigate();

  const handleOpenMenu = () => {
    freezeMenuCloseRef.current = true;
    setTimeout(() => {
      freezeMenuCloseRef.current = false;
    }, 100);
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    if (freezeMenuCloseRef.current) {
      return;
    }
    setMenuOpen(false);
  };

  const handleOpenProviderMenu = () => {
    handleCloseMenu();
    handleOpenProvider();
  }

  return (
    <header className={styles.wrap}>
      <div className={styles.inner}>
        <a href="/" className={styles.logoLink}>
          <img className={styles.logoImage} alt="logo" src="/images/logo.png" />
        </a>
        {/* <button className={styles.burger} onClick={handleToggleMenu}>
          <BurgerIcon className={styles.burgerIcon} />
        </button> */}
        <button
          type="button"
          className={styles.burgerBtn}
          onClick={handleOpenMenu}
        >
          <span
            className={cn(styles.burgerLine, styles.burgerLine1, {
              [styles.burgerLine1Active]: isMenuOpen,
            })}
          />
          <span
            className={cn(styles.burgerLine, styles.burgerLine2, {
              [styles.burgerLine2Active]: isMenuOpen,
            })}
          />
          <span
            className={cn(styles.burgerLine, styles.burgerLine3, {
              [styles.burgerLine3Active]: isMenuOpen,
            })}
          />
        </button>
        <nav className={styles.nav}>
          <a href="#about" className={styles.navLink}>
            О нас
          </a>
          <a href="#projects" className={styles.navLink}>
            Проекты
          </a>
          <a
            href="https://limevers.gitbook.io/ime-technologies"
            className={styles.navLink}
            target="_blank"
          >
            White Paper
          </a>
        </nav>
        {account ? (
          <StyledLink className={styles.connectBtn} href="/profile">
            Перейти в профиль
          </StyledLink>
        ) : (
          <Button className={styles.connectBtn} onClick={handleOpenProvider}>
            Подключить кошелек
          </Button>
        )}
      </div>

      <Menu isOpen={isMenuOpen} handleClose={handleCloseMenu} handleOpenProvider={handleOpenProviderMenu} />
      <ProviderSelect
        isOpen={isProviderSelectOpen}
        handleClose={handleCloseProvider}
      />
    </header>
  );
};
