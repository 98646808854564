'use client';

import { FC, useState } from 'react';
import Collapsible from 'react-collapsible';
import cn from 'classnames';
import styles from './faq.module.scss';
import { content } from './faq.content';
import { ArrowIcon } from '../../../components/icons';

export const Faq: FC = () => {
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);

  const toggleActiveTab = (newIndex: number) => () => {
    if (activeTabIndex === newIndex) {
      setActiveTabIndex(null);
    } else {
      setActiveTabIndex(newIndex);
    }
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.inner}>
        <h2 className={styles.title}>У вас возникли вопросы?</h2>
        <div className={styles.content}>
          {content.map((item, index) => {
            return (
              <div className={styles.accordionItemWrap} key={index}>
                <ArrowIcon
                  className={cn(styles.arrowIcon, {
                    [styles.arrowIconOpened]: index === activeTabIndex,
                  })}
                />
                <Collapsible
                  key={item.id}
                  open={index === activeTabIndex}
                  handleTriggerClick={toggleActiveTab(index)}
                  className={styles.accordionItem}
                  openedClassName={styles.accordionItemOpened}
                  triggerClassName={styles.accordionItemTrigger}
                  triggerOpenedClassName={styles.accordionItemTriggerOpened}
                  contentInnerClassName={styles.accordionItemContentInner}
                  trigger={
                    <div className={styles.accordionItemTriggerText}>
                      <span className={styles.accordionItemId}>{item.id}</span>
                      <span className={styles.accordionItemQuestion}>
                        {item.question}
                      </span>
                    </div>
                  }
                >
                  <p className={styles.accordionAnswer}>{item.answer}</p>
                </Collapsible>
              </div>
            );
          })}
        </div>
        <p className={styles.text}>
          Инвестируя в производство инновационных разработок Lime, вы делаете
          вклад не только в свое финансовое благополучие, но и в новый
          экологичный формат жизни!
        </p>
      </div>
    </div>
  );
};
