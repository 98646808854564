'use client';

import { FC, useRef, useState } from 'react';
import styles from './header.module.scss';
import cn from 'classnames';
import { Menu } from '../menu/menu';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../ui-kit';
import { useConnect } from '../../../hooks/web3/useConnect';

export const Header: FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const freezeMenuCloseRef = useRef(false);
  const { handleDisconnect } = useConnect();
  const navigate = useNavigate();

  const handleOpenMenu = () => {
    freezeMenuCloseRef.current = true;
    setTimeout(() => {
      freezeMenuCloseRef.current = false;
    }, 100);
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    if (freezeMenuCloseRef.current) {
      return;
    }
    setMenuOpen(false);
  };

  const handleLogOut = async () => {
    await handleDisconnect();
    navigate('/');
  };

  return (
    <header className={styles.wrap}>
      <div className={styles.inner}>
        <a href="/" className={styles.logoLink}>
          <img className={styles.logoImage} alt="logo" src="/images/logo.png" />
        </a>
        {/* <button className={styles.burger} onClick={handleToggleMenu}>
          <BurgerIcon className={styles.burgerIcon} />
        </button> */}
        <button
          type="button"
          className={styles.burgerBtn}
          onClick={handleOpenMenu}
        >
          <span
            className={cn(styles.burgerLine, styles.burgerLine1, {
              [styles.burgerLine1Active]: isMenuOpen,
            })}
          />
          <span
            className={cn(styles.burgerLine, styles.burgerLine2, {
              [styles.burgerLine2Active]: isMenuOpen,
            })}
          />
          <span
            className={cn(styles.burgerLine, styles.burgerLine3, {
              [styles.burgerLine3Active]: isMenuOpen,
            })}
          />
        </button>
        <nav className={styles.nav}>
          <Link
            to="/profile"
            className={cn(styles.navLink, {
              [styles.navLinkActive]: window?.location.pathname === '/profile',
            })}
          >
            Кабинет инвестора
          </Link>
          <Link
            to="/profile/history"
            className={cn(styles.navLink, {
              [styles.navLinkActive]:
                window?.location.pathname === '/profile/history',
            })}
          >
            История операций
          </Link>
          <Link
            to="/profile/partners"
            className={cn(styles.navLink, {
              [styles.navLinkActive]:
                window?.location.pathname === '/profile/partners',
            })}
          >
            Мои партнеры
          </Link>
          <Link
            to="/profile/kyc"
            className={cn(styles.navLink, {
              [styles.navLinkActive]:
                window?.location.pathname === '/profile/kyc',
            })}
          >
            KYC
          </Link>
        </nav>
        <Button className={styles.connectBtn} onClick={handleLogOut}>
          Выйти
        </Button>
      </div>

      <Menu isOpen={isMenuOpen} handleClose={handleCloseMenu} />
    </header>
  );
};
