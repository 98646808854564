export const content = [
  {
    id: '01',
    question: 'Lime это RWA холдинг?',
    answer:
      'Да, Lime является криптовалютным проектом, к которому применим термин RWA. RWA (Real World Asset) — это криптовалютный проект, использующий реальные активы, такие как недвижимость, ценные бумаги или другие материальные объекты, для поддержки своей цифровой валюты. Это способствует стабилизации цены и обеспечивает её активной поддержкой.',
  },
  {
    id: '02',
    question: 'Как работает RWA?',
    answer:
      'RWA (Real World Asset) использует реальные активы, такие как недвижимость или ценные бумаги, в качестве залога для выпуска цифровых валют или токенов, обеспечивая их стабильность и доверие. Эмитент выбирает активы, залагает их для выпуска цифровой валюты, а затем управляет активами, поддерживающими её ценность.',
  },
  {
    id: '03',
    question: 'Почему Lime полностью на Smart Contracts?',
    answer:
      'Использование смарт-контрактов выгодно, потому что они обеспечивают прозрачность, безопасность, эффективность и автоматизацию процессов, уменьшая необходимость посредников и потенциальные ошибки.',
  },
  {
    id: '04',
    question: 'Почему Lime - надежный выбор для инвестиций?',
    answer:
      'Технология RWA позволяет инвесторам получать доступ к реальным активам через цифровые токены и акции, что значительно упрощает и удешевляет инвестирование. Благодаря прозрачности блокчейна и стабильности реальных активов, инвестирование с использованием RWA становится более надежным и безопасным.',
  },
  {
    id: '05',
    question: 'Я могу заработать вместе с Lime?',
    answer:
      'Чтобы начать зарабатывать вместе с Lime Holdings, вам всего лишь нужно пройти простую регистрацию на нашем сайте. После этого вы сможете приобрести токены компании на старте ранней продажи. После покупки токенов вам станут доступны преимущества, такие как ежемесячный рост токена, участие в партнерской программе, возможность приобретения реальной продукции за токены компании и даже продажа своих токенов до официального листинга благодаря принципам обратного выкупа за счет прибыли компании Lime.',
  },
  {
    id: '06',
    question: 'Здесь есть риски?',
    answer:
      'Как и в случае с любым инвестиционным проектом, существуют определенные риски. Например, изменения в стоимости реальных активов могут повлиять на цену активов RWA проекта. Также возможны риски, связанные с техническими аспектами блокчейна или смарт-контрактов. Поэтому важно принимать решение самостоятельно и нести полную ответственность за свой выбор при инвестировании в компанию Lime. Всегда следуйте принципу "Сделай свои собственные исследования" (DYOR) при любых инвестициях.',
  },
];
