'use client';

import { FC } from 'react';
import styles from './buyTokenButtons.module.scss';

type BuyTokenButtonsProps = {
  tokenPrice?: number;
  onBuyToken: VoidFunction;
  onSellToken: VoidFunction;
  isSellEnabled: boolean;
};

export const BuyTokenButtons: FC<BuyTokenButtonsProps> = ({
  tokenPrice = 0,
  onBuyToken,
  onSellToken,
  isSellEnabled,
}) => {
  return (
    <div className={styles.bottomBlockWrapper}>
      <div className={styles.tokenPriceWrapper}>
        <span>Курс токена:</span>
        <span>{tokenPrice.toFixed(4)}</span>
      </div>
      <div className={styles.buttonWrapper}>
        <button onClick={onBuyToken} className={styles.buyTokenButton}>
          Купить токен
        </button>
        <button
          onClick={onSellToken}
          className={styles.buyTokenButton}
          disabled={!isSellEnabled}
        >
          Продать токен
        </button>
      </div>
    </div>
  );
};
