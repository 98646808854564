import { Contract } from 'ethers';

export const getAllowance = async (
  tokenContract: Contract,
  account: string,
  contractAddress: string
) => {
  console.log('getAllowance', account, contractAddress);

  const data = (await tokenContract.getFunction('allowance')(
    account,
    contractAddress
  )) as bigint;
  return data;
};
