import { Contract } from 'ethers';

export const getBuyBackTime = async (
  saleContract: Contract,
  account: string
) => {
  const data = (await saleContract.getFunction('buyBackTime')(
    account
  )) as bigint;
  return Number(data);
};
