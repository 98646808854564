import { FC, FormEvent, useEffect, useState } from 'react';

import styles from './receiveBonusModal.module.scss';
import { useWeb3React } from '@web3-react/core';
import {
  useBonusesContract,
  usePriceRateContract,
} from '../../../../../hooks/web3/useContract';
import { Popup } from '../../../../../ui-kit';
import { withdrawBonuses } from '../../../../../utils/web3/withdrawBonuses';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ReceiveBonusModal: FC<Props> = ({ isOpen, onClose }) => {
  const { account } = useWeb3React();
  const [bonusCount, setBonusCount] = useState(0);
  const [bonusAvailable, setBonusAvailable] = useState(0);
  const bonusesContract = useBonusesContract();

  useEffect(() => {
    const init = async () => {
      if (!account || !bonusesContract) {
        return;
      }

      const data = (await bonusesContract.getFunction('getBalance')(
        account
      )) as bigint;
      setBonusAvailable(Number(data / BigInt(10 ** 18)));
    };

    init().catch(console.error);
  }, [bonusesContract, account]);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!value) {
      setBonusCount(0);
    }

    if (isNaN(+value)) {
      return;
    }

    const newValue = Number(value);
    // todo clamp
    setBonusCount(newValue);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!bonusesContract) {
      return;
    }
    await withdrawBonuses(bonusesContract, bonusCount);
    onClose();
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={styles.modalContent}
    >
      <>
        <div className={styles.header}>
          <span className={styles.headerTitle}>Кошелек:</span>
          <span className={styles.wallet}>{account}</span>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.valueBlock}>
            <span className={styles.valueTitle}>Количество токенов (USDT):</span>
            <input
              className={styles.valueInput}
              placeholder="0"
              value={bonusCount}
              onChange={handleChangeValue}
            />
          </div>
          <span className={styles.available}>
            Доступно {bonusAvailable} USDT
          </span>
          <button type="submit" className={styles.submitBtn}>
            ПОЛУЧИТЬ
          </button>
        </form>
      </>
    </Popup>
  );
};
