import { useEffect, useState } from 'react';
import { User } from '../../../../api/types';
import { getUser } from '../../../../api';

export const useKyc = (address: string | undefined) => {
  const [userData, setUserData] = useState<Partial<User>>({});

  useEffect(() => {
    const init = async () => {
      if (!address) {
        return;
      }
      const newUserData = await getUser(address);

      setUserData(newUserData);
    };

    init().catch(console.error);
  }, [address]);

  return {
    userData,
  };
};
