export const content = [
  {
    id: '0',
    imagePng: '/images/main/projects/motorcycle.png',
    imageWebp: '/images/main/projects/motorcycle.webp',
    isDone: true,
    info: {
      title: 'I кв 2022 г.',
      text: 'Производство и модернизация малой мототехники (баги, квадроциклы, мотоциклы)',
    },
  },
  {
    id: '1',
    imagePng: '/images/main/projects/shampoo.png',
    imageWebp: '/images/main/projects/shampoo.png',
    isDone: true,
    info: {
      title: 'III кв 2022 г.',
      text: 'Производство премиальной продукции Din Premium',
    },
  },
  {
    id: '2',
    imagePng: '/images/main/projects/phone.png',
    imageWebp: '/images/main/projects/phone.webp',
    isDone: true,
    info: {
      title: 'III кв 2024 г.',
      text: 'Маркетплейс Dada Bazar',
    },
  },
  {
    id: '3',
    imagePng: '/images/main/projects/paraffin.png',
    imageWebp: '/images/main/projects/paraffin.png',
    isDone: true,
    info: {
      title: 'III кв 2024 г.',
      text: 'Производство медицинского парафина',
    },
  },
  {
    id: '4',
    imagePng: '/images/main/projects/stabilize.png',
    imageWebp: '/images/main/projects/stabilize.webp',
    isDone: false,
    info: {
      title: ' IV кв 2024 г.',
      text: 'Производство инверторных стабилизаторов',
    },
  },
  {
    id: '5',
    imagePng: '/images/main/projects/powerbank.png',
    imageWebp: '/images/main/projects/powerbank.webp',
    isDone: false,
    info: {
      title: 'II кв 2025 г.',
      text: 'Производство автономных пауербанков',
    },
  },
  {
    id: '7',
    imagePng: '/images/main/projects/houses.png',
    imageWebp: '/images/main/projects/houses.webp',
    isDone: false,
    info: {
      title: 'IV кв 2025 г.',
      text: 'Производство автономных домов фахверк',
    },
  },
  // {
  //   id: '6',
  //   imagePng: '/images/main/projects/boxes.png',
  //   imageWebp: '/images/main/projects/boxes.webp',
  //   isDone: true,
  //   info: {
  //     title: 'II кв 2025 г.',
  //     text: 'Производство автономных пауербанков',
  //   },
  // },
];
