import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      d="M1 21 21 1m0 0C17.322 3.759 7.207 6.517 3.069 2.38M21 1c-2.759 3.218-6.62 11.31 0 17.931"
    />
  </svg>
);
export { SvgComponent as ArrowIcon };
