import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MainPage } from './pages/main/main';
import { ProfilePage } from './pages/profile/profile';
import { KycPage } from './pages/kyc/kyc';
import { PartnersPage } from './pages/partners/partners';
import { HistoryPage } from './pages/history/history';
import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    const searchParams = Object.fromEntries(
      window.location.search
        .replace('?', '')
        .split('&')
        .map((item) => item.split('='))
    );
    if (searchParams.refId) {
      localStorage.setItem('refId', searchParams.refId);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<MainPage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="profile/kyc" element={<KycPage />} />
          <Route path="profile/partners" element={<PartnersPage />} />
          <Route path="profile/history" element={<HistoryPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
