import { initializeConnector } from '@web3-react/core';
import { WalletConnect } from '@web3-react/walletconnect-v2';

import { BSC, BSCTestnet } from '../chain';

export const [walletConnect, hooks, store] = initializeConnector<WalletConnect>(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        projectId: '1d1e0f4fa8c80a55424aa8cf32e9b2db',
        chains: [BSC.chainId],
        showQrModal: true,
        rpc: {
          [BSC.chainId]: BSC.rpcUrls,
        },
      },
    })
);
