import { FC, useEffect, useState } from 'react';
import styles from './secondBar.module.scss';
import { CopyIcon } from '../../cabinet/subcomponents/partnerLinkBlock/icon';
import cn from 'classnames';
import { useWeb3React } from '@web3-react/core';
import {
  useInvestTokenContract,
  useSaleContract,
} from '../../../../../hooks/web3/useContract';
import { getUserLevel } from '../../../../../utils/web3/getUserLevel';
import { getNextLevelRequirements } from '../../../../../utils/web3/getNextLevelRequirements';
import { getUserInvestTokenBalance } from '../../../../../utils/web3/getUserInvestTokenBalance';
import { getTeamAmount } from '../../../../../utils/web3/getTeamAmount';
import { getPartnersByAccount } from '../../../../../utils/web3/getPartnersByAccount';
import { getSpendUsdtAmount } from '../../../../../utils/web3/getSpendUsdtAmount';
import { getUserInvider } from '../../../../../utils/web3/getUserInviter';

type SecondBarProps = {
  showModal: VoidFunction;
  showReceiveBonusModal: VoidFunction;
};

export const SecondBar: FC<SecondBarProps> = ({
  showModal,
  showReceiveBonusModal,
}) => {
  const { account = '' } = useWeb3React();
  const link = `${window.location.origin}?refId=${account}`;
  const saleContract = useSaleContract();
  const investTokenContract = useInvestTokenContract();
  const [inviterAddress, setInviterAddress] = useState('');
  const [levelCompletionStats, setLevelCompletionStats] = useState({
    team: 0,
    personal: 0,
  });

  const handleCopyClick = () => {
    void navigator.clipboard.writeText(link);
    alert('Ссылка скопирована');
  };

  const handleCopyPartnerClick = () => {
    void navigator.clipboard.writeText(inviterAddress);
    alert('Адрес скопирован');
  };

  useEffect(() => {
    const init = async () => {
      if (!account || !saleContract || !investTokenContract) {
        return;
      }

      const inviter = await getUserInvider(saleContract, account);
      setInviterAddress(inviter);

      const userLevel = await getUserLevel(saleContract, account);
      const { amount: amountNeeded, teamAmount: teamAmountNeeded } =
        await getNextLevelRequirements(userLevel, saleContract);

      const currentAmount = await getSpendUsdtAmount(saleContract, account);

      const firstLine = await getPartnersByAccount(saleContract, account);

      const newTeamAmount = await getTeamAmount(
        account,
        firstLine,
        saleContract
      );

      // почекать втф 100 процентов

      console.log({ newTeamAmount, teamAmountNeeded });
      console.log({ currentAmount, amountNeeded });

      const prettifyDivision = (a: number, b: number) =>
        Math.min(Math.round((a / b) * 100), 100);

      setLevelCompletionStats({
        team: prettifyDivision(newTeamAmount, teamAmountNeeded),
        personal: prettifyDivision(currentAmount, amountNeeded),
      });
    };

    init().catch(console.error);
  }, [account, saleContract, investTokenContract]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.subWrapper}>
        <div className={styles.block} onClick={handleCopyPartnerClick}>
          <span className={styles.blockHeader}>Вас пригласили:</span>
          <span className={styles.partnerLinkWrapper}>
            <span className={styles.partnerLink}>{inviterAddress}</span>
            <button className={styles.partnerLinkButton}>
              <CopyIcon />
            </button>
          </span>
        </div>

        <div className={styles.block} onClick={handleCopyClick}>
          <span className={styles.blockHeader}>Партнерская ссылка:</span>
          <span className={styles.partnerLinkWrapper}>
            <span className={styles.partnerLink}>{link}</span>
            <button className={styles.partnerLinkButton}>
              <CopyIcon />
            </button>
          </span>
        </div>

        <div className={styles.block}>
          <span className={cn(styles.blockHeader, styles.headerCenterOnTablet)}>
            Партнерский бонус:
          </span>
          <button className={styles.button} onClick={showReceiveBonusModal}>
            Получить
          </button>
        </div>

        <div className={styles.block}>
          <span className={cn(styles.blockHeader, styles.headerCenterOnTablet)}>
            Презентация партнера:
          </span>
          <a
            className={styles.button}
            target={'_blank'}
            href={'/Партнерская_программа.pdf'}
          >
            Посмотреть
          </a>
        </div>
      </div>
      <div className={styles.nextLevelWrapper}>
        <span className={styles.blockHeader}> Следующий ранг:</span>

        <div className={styles.nextLevelItemWrapper}>
          <div className={styles.nextLevelItem}>
            <span className={styles.nextLevelItemHeader}>Личный портфель:</span>
            <div className={styles.nextLevelItemBar}>
              <span className={styles.nextLevelItemBarText}>
                {levelCompletionStats.personal}%
              </span>
              <div
                className={styles.nextLevelItemBarContent}
                style={{ width: `${levelCompletionStats.personal}%` }}
              />
            </div>
          </div>

          <div className={styles.nextLevelItem}>
            <span className={styles.nextLevelItemHeader}>
              Командный портфель:
            </span>
            <div className={styles.nextLevelItemBar}>
              <span className={styles.nextLevelItemBarText}>
                {levelCompletionStats.team}%
              </span>
              <div
                className={styles.nextLevelItemBarContent}
                style={{ width: `${levelCompletionStats.team}%` }}
              />
            </div>
          </div>

          {/* <div className={styles.nextLevelItem}>
            <span className={styles.nextLevelItemHeader}>Баланс:</span>
            <div className={styles.nextLevelItemBar}>
              <span className={styles.nextLevelItemBarText}>20%</span>
              <div
                className={styles.nextLevelItemBarContent}
                style={{ width: '20%' }}
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
