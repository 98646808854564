'use client';

import { FC, useRef } from 'react';
import cn from 'classnames';
import styles from './presale.module.scss';
import { useGSAP } from '@gsap/react';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import gsap from 'gsap';

gsap.registerPlugin(ScrollTrigger);

type PresaleProps = {
  openProviderSelect: VoidFunction;
}

export const Presale: FC<PresaleProps> = ({ openProviderSelect }) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    const titleTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });
    const contentTL = gsap.timeline({
      repeat: 0,
      scrollTrigger: wrapRef.current,
    });

    titleTL.to(titleRef.current, {
      opacity: '1',
      duration: 0.5,
    });

    contentTL.to(contentRef.current, {
      opacity: '1',
      duration: 0.5,
      delay: 0.3,
    });
  });

  return (
    <div className={styles.wrap}>
      <div className={styles.inner} ref={wrapRef}>
        <h2 className={styles.title} ref={titleRef}>
          ПОЧЕМУ ВЫГОДНО <br /> ПОКУПАТЬ LIME НА&nbsp;Pre-sale
        </h2>
        <div className={styles.content} ref={contentRef}>
          <div className={cn(styles.card, styles.card1)}>
            <div className={styles.cardInner}>
              Цена на Pre-sale гораздо ниже цены на&nbsp;листинге - больше доход
            </div>
          </div>
          <div className={cn(styles.card, styles.card2)}>
            <div className={styles.cardInner}>
              Обьем продаж токена на&nbsp;Pre-sale ограничен 12% от всей эмиссии
              - быстро закончатся.
            </div>
          </div>
          <div className={cn(styles.card, styles.card3)}>
            <div className={styles.cardInner}>
              Стартовая цена 0.1$ и&nbsp;каждый месяц приростает на&nbsp;5% -
              выгода еще до&nbsp;листинга
            </div>
          </div>
          <div className={styles.linkBlock}>
            <button onClick={openProviderSelect} className={styles.link}>
              Купить на&nbsp;ICO
            </button>
          </div>
          <div className={cn(styles.card, styles.card4)}>
            <div className={styles.cardInner}>
              Гарантия выкупа токена Lime холдингом еще до&nbsp;листинга -
              надёжность
            </div>
          </div>
          <div className={cn(styles.card, styles.card5)}>
            <div className={styles.cardInner}>
              Токен Lime позволяет приобретать продукты компании дешевле,
              включая недвижимость - выгодные сделки
            </div>
          </div>
          <div className={cn(styles.card, styles.card6)}>
            <div className={styles.cardInner}>
              Рост токена обеспечен прибылью холдинга и не зависит от рынка -
              стабильный ежемесячный доход
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
