import axios from 'axios';
import {
  BackendTransaction,
  LandingInfo,
  PriceGraphPoint,
  User,
  UserInfo,
} from './types';

const apiUrl =
  process.env.REACT_APP_API_URL ?? 'https://lime-back.crypto-loto.xyz/';

export const updateOrCreateUser = async (payload: User): Promise<User> => {
  const { data } = await axios.post<User>(`${apiUrl}/api/v1/user`, payload);
  return data;
};

export const getUser = async (address: string): Promise<User> => {
  const { data } = await axios.get<User>(
    `${apiUrl}/api/v1/user?address=${address}`
  );
  return data;
};

export const getTransactionsByAddress = async (address: string) => {
  const { data } = await axios.get<BackendTransaction[]>(
    `${apiUrl}/api/v1/transactions?address=${address}`
  );
  return data;
};

export const getPriceGraph = async () => {
  const { data } = await axios.get<PriceGraphPoint[]>(
    `${apiUrl}/api/v1/price_graph`
  );
  return data;
};

export const getLandingInfo = async () => {
  const { data } = await axios.get<LandingInfo>(
    `${apiUrl}/api/v1/landing-info`
  );
  return data;
};

export const getUserInfo = async (wallet: string) => {
  const { data } = await axios.get<UserInfo>(
    `${apiUrl}/api/v1/user-info?wallet=${wallet}`
  );
  return data;
};
