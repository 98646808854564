import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/typography.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Web3ReactProvider, Web3ReactHooks } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { metaMask, hooks as metaMaskHooks } from './web3/connectors/metaMask';
import {
  walletConnect,
  hooks as walletConnectHooks,
} from './web3/connectors/walletConnect';
import './styles/globals.scss';
import { Footer } from './components/global';

export const connectors: Array<[MetaMask | WalletConnect, Web3ReactHooks]> = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
];

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Web3ReactProvider connectors={connectors}>
      <App />
      <Footer />
    </Web3ReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
