'use client';

import { FC, useEffect, useState } from 'react';
import styles from './partnerLinkBlock.module.scss';
import { CopyIcon } from './icon';
import { BuyTokenButtons } from '../buyTokenButtons';
import QrSvg from '@wojtekmaj/react-qr-svg';
import { useWeb3React } from '@web3-react/core';
import { useSaleContract } from '../../../../../../hooks/web3/useContract';
import { getBuyBackTime } from '../../../../../../utils/web3/getBuyBackTime';

type PartnerLinkBlockProps = {
  link: string;
  handleOpenBuyModal: () => void;
  handleOpenSellModal: () => void;
};

export const PartnerLinkBlock: FC<PartnerLinkBlockProps> = ({
  link,
  handleOpenBuyModal,
  handleOpenSellModal,
}) => {
  const [isSellEnabled, setSellEnabled] = useState(false);
  const { account } = useWeb3React();
  const saleContract = useSaleContract();

  useEffect(() => {
    const init = async () => {
      if (!account || !saleContract) {
        return;
      }
      const buyBackTime = await getBuyBackTime(saleContract, account);

      const isEnabled = Date.now() > buyBackTime * 1000;
      setSellEnabled(isEnabled);
      if (isEnabled) {
        return;
      }
      setTimeout(() => {
        setSellEnabled(true);
      }, buyBackTime * 1000 - Date.now());
    };

    init().catch(console.error);
  }, [account]);

  const handleCopyClick = () => {
    void navigator.clipboard.writeText(link);
    alert('Ссылка скопирована');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.partnerLinkContainer}>
        <div className={styles.partnerLinkWrapper}>
          <div className={styles.partnerLinkHeader}>Партнерская ссылка:</div>
          <button
            onClick={handleCopyClick}
            className={styles.partnerLinkContent}
          >
            <span className={styles.link}>{link}</span>
            <CopyIcon />
          </button>
        </div>
        <div className={styles.buyTokenButtonWrapper}>
          <BuyTokenButtons
            onBuyToken={handleOpenBuyModal}
            onSellToken={handleOpenSellModal}
            isSellEnabled={isSellEnabled}
          />
        </div>
      </div>
      <div className={styles.qrCodeWrapper}>
        <QrSvg value={link} bgColor="transparent" fgColor="white" />
      </div>
    </div>
  );
};
