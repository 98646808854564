import { Contract } from 'ethers';

export const withdrawBonuses = async (
  bonusContract: Contract,
  amount: number
) => {
  const bigIntAmount = BigInt(amount) * BigInt(10 ** 18);
  const { wait } = (await bonusContract.getFunction('withdraw')(
    bigIntAmount
  )) as {
    wait: () => Promise<void>;
  };
  await wait();
};
